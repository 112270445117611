import React, { useState, useRef, useEffect } from 'react'
import './Accordion.css'
import { Row, Col } from 'react-bootstrap';
import { DefaultPlayer as Video } from 'react-html5video';
import Accordion from 'react-bootstrap/Accordion';
import { TbAppsFilled } from "react-icons/tb";
import { IoIosSearch } from "react-icons/io";
import { FiBookmark } from "react-icons/fi";
import { CgNotes } from "react-icons/cg";
import { HiMiniLink } from "react-icons/hi2";
import { RiSettings5Fill } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";
import { FaVideo } from "react-icons/fa6";
import { BsChatSquareText } from "react-icons/bs";
import { BiHighlight } from "react-icons/bi";
import { RiFilePaperLine } from "react-icons/ri";
import { MdAddLink } from "react-icons/md";
import { ImBook } from "react-icons/im";
import { IoMdTime } from "react-icons/io";
import { FaCaretDown } from "react-icons/fa6";
import { IoList } from "react-icons/io5";
import { MdOutlineExpand } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { FaRegComment } from "react-icons/fa";
import { FaNoteSticky } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdShareAlt } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoChevronUp } from "react-icons/io5";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";

import smacc from "../Assets/Ksmacc.png"
import SearchIcon from '../Icons/SearchIcon';
import KlarifyIcon from '../Icons/KlarifyIcon';
import NotesIcon from '../Icons/NotesIcon';
import BookmarkIcon from '../Icons/BookmarkIcon';
import HyperlinkIcon from '../Icons/HyperlinkIcon';
import SettingsIcon from '../Icons/SettingsIcon';
import HighlightIcon from '../Icons/HighlightIcon';
import KLogo from '../Assets/KatbookLogo.png'
import ProPic from '../Assets/ProfilePic.png'
import ProceumLogo from '../Assets/ProceumLogo.png'
import img1 from "../Assets/img1.png"
import img2 from "../Assets/img2.png"
import img3 from "../Assets/img3.png"
import PImg from "../Assets/proImg.png"
import clarify from "../Assets/clarify.png"
import ReactPlayer from 'react-player'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Accordionn = () => {
    const responsive = {
        superLargeDesktop: {
            // The name can be any, this is just for large screens
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const [isActive, setIsActive] = useState(0);
    const [showProfile, setShowProfile] = useState(false);
    const [mainMenu, setMainMenu] = useState(true);
    const [showHighlight, setShowHighlight] = useState(false);
    const [showClarify, setShowClarify] = useState(false);
    const [showHyperlink, setShowHyperlink] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [showAdditionalContent, setShowAdditionalContent] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [showAnswerCases, setShowAnswerCases] = useState(false);
    const [showIndexModal, setShowIndexModal] = useState(false);

    const handleClose = () => setShowIndexModal(false);
    const handleShow = () => setShowIndexModal(true);

    const handleMenuClick = (key) => {
        if (isActive === key && key !== 0) {
            setIsActive(isActive)
        } else {
            setIsActive(key); // Sets the active key
        }
    }

    const getItemStyle = (key) => {
        return isActive === key || (isActive === 0 && key === 0)
            ? { backgroundColor: '#18498b', color: '#fff' }
            : {};
    };

    const videoRef = useRef(null);
    const [currentSegment, setCurrentSegment] = useState(null);


    useEffect(() => {
        if (currentSegment && videoRef.current) {
            videoRef.current.currentTime = currentSegment.start;
            videoRef.current.play();

            const handleTimeUpdate = () => {
                if (videoRef.current.currentTime >= currentSegment.end) {
                    videoRef.current.pause();
                }
            };

            videoRef.current.addEventListener('timeupdate', handleTimeUpdate);

            return () => {
                videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            };
        }
    }, [currentSegment]);

    const playSegment = (start, end) => {
        setCurrentSegment({ start, end });
    };


    return (
        <div>
            <div className='d-flex align-items-center justify-content-between px-4 py-2 border_bottom'>
                <div>
                    <img src={ProceumLogo} alt='ProceumLogo' width={"200px"} />
                </div>
                <div className='d-flex flex-row justify-content-end align-items-center gap-2'>
                    <div className='d-flex align-items-center gap-2'>
                        <div>
                            <img src={ProPic} alt='ProfileLogo' />
                        </div>
                        <div className='title_font cursor position-relative'
                            onClick={() => setShowProfile(!showProfile)}>
                            Ramesh K <FaCaretDown size={18} />
                        </div>
                        <div className=''>
                            {showProfile &&
                                <div className='align_profile '>
                                    <div className='p-2 text-center border_bottom'>
                                        Account
                                    </div>
                                    <div className='py-2 px-3 border_bottom'>
                                        <div className='mb-2'>
                                            Profile Name
                                        </div>
                                        <div>
                                            User Name
                                        </div>
                                    </div>
                                    <div className='py-2 px-3 cursor'>
                                        Logout
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div>
                        <img src={KLogo} alt='KatbookLogo' />
                    </div>
                </div>
            </div>

            <Row className='w-100'>
                <Col sm={4} md={3}>
                    {mainMenu &&
                        <div className='d-flex flex-column border_right'>
                            <div style={getItemStyle('0')}
                                className={`d-flex align-items-center gap-3 px-4 py-3 border_bottom cursor ${isActive === 0 ? "menu_background" : "font"}`}
                                onClick={() => handleMenuClick(0)}>
                                <div>
                                    <FaVideo size={23} />
                                </div>
                                <div className='menu_font'>
                                    Videos
                                </div>
                            </div>
                            <div style={getItemStyle('1')}
                                className={`d-flex align-items-center gap-3 px-4 py-3 border_bottom cursor ${isActive === 1 ? "menu_background" : "font"}`}
                                onClick={() => handleMenuClick(1)}>
                                <div>
                                    <ImBook size={23} />
                                </div>
                                <div className='menu_font'>
                                    Lecture Notes
                                </div>
                            </div>
                            <div style={getItemStyle('2')}
                                className={`d-flex align-items-center gap-3 px-4 py-3 border_bottom cursor ${isActive === 2 ? "menu_background" : "font"}`}
                                onClick={() => handleMenuClick(2)}>
                                <div>
                                    <BiHighlight size={23} />
                                </div>
                                <div className='menu_font'>
                                    Highyield
                                </div>
                            </div>
                            <div style={getItemStyle('3')}
                                className={`d-flex align-items-center gap-3 px-4 py-3 border_bottom cursor ${isActive === 3 ? "menu_background" : "font"}`}
                                onClick={() => handleMenuClick(3)}>
                                <div>
                                    <BsChatSquareText size={23} />
                                </div>
                                <div className='menu_font'>
                                    MCQs
                                </div>
                            </div>
                            <div style={getItemStyle('4')}
                                className={`d-flex align-items-center gap-3 px-4 py-3 border_bottom cursor ${isActive === 4 ? "menu_background" : "font"}`}
                                onClick={() => handleMenuClick(4)}>
                                <div>
                                    <RiFilePaperLine size={23} />
                                </div>
                                <div className='menu_font'>
                                    Cases
                                </div>
                            </div>
                            <div style={getItemStyle('5')}
                                className={`d-flex align-items-center gap-3 px-4 py-3 border_bottom cursor ${isActive === 5 ? "menu_background" : "font"}`}
                                onClick={() => handleMenuClick(5)}>
                                <div>
                                    <MdAddLink size={23} />
                                </div>
                                <div className='menu_font'>
                                    External Reference
                                </div>

                            </div>
                        </div>
                    }

                    {showClarify &&
                        <div className='d-flex flex-column border_right'>
                            <div className='d-flex justify-content-between align-items-center px-3 py-3 side_header'>
                                <div className='side_font'>
                                    Clarify
                                </div>
                                <div className='cursor'>
                                    <IoCloseOutline size={25} onClick={() => { setMainMenu(true); setShowClarify(false) }} />
                                </div>
                            </div>
                            <div className='height_fea'>
                                <div className='p-3'>
                                    <div className='mb-2 d-flex align-items-center gap-2 p-2 dot_border'>
                                        <div className='one'>
                                            1
                                        </div>
                                        <div className='sf'>
                                            skeletal framework
                                        </div>
                                    </div>

                                    <div>
                                        <div className='d-flex align-items-start justify-content-between'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <div>
                                                    <img src={PImg} alt='' />
                                                </div>
                                                <div>
                                                    <div className='clarif1'>
                                                        Student - Baskar K
                                                    </div>
                                                    <div className='clarif'>
                                                        Request for clarification<br />
                                                        14 Aug 2024 &nbsp; 8:37 AM
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='yt'>
                                                Yet to Clarify (2)
                                            </div>
                                        </div>
                                        <div className='pt-2'>
                                            Can you explain what is skeletal framework ?
                                        </div>
                                        <div className='com pt-3 d-flex align-items-center justify-content-end'>
                                            <FaRegComment size={23} /> &nbsp; Comment
                                        </div>
                                    </div>
                                </div>

                                <div className='p-3'>
                                    <div className='mb-2 d-flex align-items-center gap-2 p-2 dot_border'>
                                        <div className='one'>
                                            1
                                        </div>
                                        <div className='sf'>
                                            skeletal
                                        </div>
                                    </div>

                                    <div>
                                        <div className='d-flex align-items-start justify-content-between'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <div>
                                                    <img src={PImg} alt='' />
                                                </div>
                                                <div>
                                                    <div className='clarif1'>
                                                        Student - Baskar K
                                                    </div>
                                                    <div className='clarif'>
                                                        Request for clarification<br />
                                                        14 Aug 2024 &nbsp; 8:37 AM
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='yt'>
                                                Yet to Clarify (2)
                                            </div>
                                        </div>
                                        <div className='pt-2'>
                                            Can you explain what is skeletal framework ?
                                        </div>
                                        <div className='com pt-3 d-flex align-items-center justify-content-end'>
                                            <FaRegComment size={23} /> &nbsp; Comment
                                        </div>
                                    </div>
                                </div>

                                <div className='p-3'>
                                    <div className='mb-2 d-flex align-items-center gap-2 p-2 dot_border'>
                                        <div className='one'>
                                            1
                                        </div>
                                        <div className='sf'>
                                            skeletal framework
                                        </div>
                                    </div>

                                    <div>
                                        <div className='d-flex align-items-start justify-content-between'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <div>
                                                    <img src={PImg} alt='' />
                                                </div>
                                                <div>
                                                    <div className='clarif1'>
                                                        Student - Baskar K
                                                    </div>
                                                    <div className='clarif'>
                                                        Request for clarification<br />
                                                        14 Aug 2024 &nbsp; 8:37 AM
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='yt'>
                                                Yet to Clarify (2)
                                            </div>
                                        </div>
                                        <div className='pt-2'>
                                            Can you explain what is skeletal framework ?
                                        </div>
                                        <div className='com pt-3 d-flex align-items-center justify-content-end'>
                                            <FaRegComment size={23} /> &nbsp; Comment
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {showNotes &&
                        <div className='d-flex flex-column border_right'>
                            <div className='d-flex justify-content-between align-items-center px-3 py-3 side_header'>
                                <div className='side_font'>
                                    Notes
                                </div>
                                <div className='cursor'>
                                    <IoCloseOutline size={25} onClick={() => { setMainMenu(true); setShowNotes(false) }} />
                                </div>
                            </div>
                            <div className='height_fea'>
                                <div className='p-2'>
                                    <div className='note'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                Unit 6 - Bones of Thoracic Cage
                                            </div>
                                            <div className='d-flex align-items-center gap-2'>
                                                <FaRegEdit size={15} />
                                                <RiDeleteBinLine size={15} />
                                            </div>
                                        </div>
                                        <div className='py-1' style={{ color: "#37f", fontWeight: "600" }}>
                                            Session - 2
                                        </div>
                                        <div>
                                            The thoracic vertebrae are a set of 12 vertebrae that form the middle section of the vertebral column, spanning most of the chest cavity.
                                        </div>
                                    </div>
                                </div>
                                <div className='p-2'>
                                    <div className='note'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                Unit 6 - Bones of Thoracic Cage
                                            </div>
                                            <div className='d-flex align-items-center gap-2'>
                                                <FaRegEdit size={15} />
                                                <RiDeleteBinLine size={15} />
                                            </div>
                                        </div>
                                        <div className='py-1' style={{ color: "#37f", fontWeight: "600" }}>
                                            Session - 3
                                        </div>
                                        <div>
                                            The body of a thoracic vertebra is somewhat “heart-shaped,” and is larger than the cervical but smaller than the lumbar vertebrae in size. The body also has small, smooth, and somewhat concave costal facets for the attachment of the ribs.
                                        </div>
                                    </div>
                                </div>
                                <div className='p-2'>
                                    <div className='note'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                Unit 6 - Bones of Thoracic Cage
                                            </div>
                                            <div className='d-flex align-items-center gap-2'>
                                                <FaRegEdit size={15} />
                                                <RiDeleteBinLine size={15} />
                                            </div>
                                        </div>
                                        <div className='py-1' style={{ color: "#37f", fontWeight: "600" }}>
                                            Session - 5
                                        </div>
                                        <div>
                                            Severe trauma to a rib may cause it to break, leading to pain, difficulty moving, and difficulty breathing.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {showHyperlink &&
                        <div className='d-flex flex-column border_right'>
                            <div className='d-flex justify-content-between align-items-center px-3 py-3 side_header'>
                                <div className='side_font'>
                                    Hyperlink
                                </div>
                                <div className='cursor'>
                                    <IoCloseOutline size={25} onClick={() => { setMainMenu(true); setShowHyperlink(false) }} />
                                </div>
                            </div>
                            <div className='height_fea'>
                                <div className='p-2'>
                                    <div className='note'>
                                        <div className='d-flex justify-content-between align-items-center pb-2'>
                                            <div className='d-flex flex-column'>
                                                <div style={{ fontWeight: "500" }}>
                                                    Unit 6 - Bones of Thoracic Cage
                                                </div>
                                                <div style={{ fontWeight: "500" }}>
                                                    Session - 2
                                                </div>
                                            </div>
                                            <div>
                                                29 Aug 2024
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between gap-2'>
                                            <div className='note w-100'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <div style={{ fontWeight: "600" }}>Sternum </div>
                                                    <div style={{ fontWeight: "400" }}> Unit 6 - Bones of Thoracic Cage</div>
                                                    <div> Session - 2</div>
                                                </div>
                                                <div className=''>
                                                    Sternum
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-evenly'>
                                                <div><IoMdShareAlt size={20} /></div>
                                                <div><FaRegEdit size={20} /></div>
                                                <div><RiDeleteBinLine size={20} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='p-2'>
                                    <div className='note'>
                                        <div className='d-flex justify-content-between align-items-center pb-2'>
                                            <div className='d-flex flex-column'>
                                                <div style={{ fontWeight: "500" }}>
                                                    Unit 6 - Bones of Thoracic Cage
                                                </div>
                                                <div style={{ fontWeight: "500" }}>
                                                    Session - 2
                                                </div>
                                            </div>
                                            <div>
                                                29 Aug 2024
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between gap-2'>
                                            <div className='note w-100'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <div style={{ fontWeight: "600" }}>Sternum </div>
                                                    <div style={{ fontWeight: "400" }}> Unit 6 - Bones of Thoracic Cage</div>
                                                    <div> Session - 2</div>
                                                </div>
                                                <div className=''>
                                                    Sternum
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-evenly'>
                                                <div><IoMdShareAlt size={20} /></div>
                                                <div><FaRegEdit size={20} /></div>
                                                <div><RiDeleteBinLine size={20} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='p-2'>
                                    <div className='note'>
                                        <div className='d-flex justify-content-between align-items-center pb-2'>
                                            <div className='d-flex flex-column'>
                                                <div style={{ fontWeight: "500" }}>
                                                    Unit 6 - Bones of Thoracic Cage
                                                </div>
                                                <div style={{ fontWeight: "500" }}>
                                                    Session - 2
                                                </div>
                                            </div>
                                            <div>
                                                29 Aug 2024
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between gap-2'>
                                            <div className='note w-100'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <div style={{ fontWeight: "600" }}>Sternum </div>
                                                    <div style={{ fontWeight: "400" }}> Unit 6 - Bones of Thoracic Cage</div>
                                                    <div> Session - 2</div>
                                                </div>
                                                <div className=''>
                                                    Sternum
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-evenly'>
                                                <div><IoMdShareAlt size={20} /></div>
                                                <div><FaRegEdit size={20} /></div>
                                                <div><RiDeleteBinLine size={20} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {showHighlight &&
                        <div className='d-flex flex-column border_right'>
                            <div className='d-flex justify-content-between align-items-center px-3 py-3 side_header'>
                                <div className='side_font'>
                                    Highlight
                                </div>
                                <div className='cursor'>
                                    <IoCloseOutline size={25} onClick={() => { setMainMenu(true); setShowHighlight(false) }} />
                                </div>
                            </div>
                            <div className='height_fea'>
                                <div className='p-2'>
                                    <div className='note'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex flex-column'>
                                                <div style={{ fontWeight: "500" }}>
                                                    Unit 6 - Bones of Thoracic Cage
                                                </div>
                                                <div style={{ fontWeight: "500" }}>
                                                    Session - 2
                                                </div>
                                            </div>
                                            <div>
                                                29 Aug 2024
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            It consists of three parts: the manubrium, body, and xiphoid process.
                                        </div>
                                        <div className='d-flex align-items-center gap-1 mt-2'>
                                            <div>
                                                <IoMdShareAlt size={20} />
                                            </div>
                                            <div className='pal'></div>
                                            <MdKeyboardArrowDown size={15} />
                                            <div style={{ paddingLeft: "10px", cursor: "pointer" }}><RiDeleteBinLine size={20} /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='p-2'>
                                    <div className='note'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex flex-column'>
                                                <div style={{ fontWeight: "500" }}>
                                                    Unit 6 - Bones of Thoracic Cage
                                                </div>
                                                <div style={{ fontWeight: "500" }}>
                                                    Session - 2
                                                </div>
                                            </div>
                                            <div>
                                                29 Aug 2024
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            It consists of three parts: the manubrium, body, and xiphoid process.
                                        </div>
                                        <div className='d-flex align-items-center gap-1 mt-2'>
                                            <div>
                                                <IoMdShareAlt size={20} />
                                            </div>
                                            <div className='pal'></div>
                                            <MdKeyboardArrowDown size={15} />
                                            <div style={{ paddingLeft: "10px", cursor: "pointer" }}><RiDeleteBinLine size={20} /></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='p-2'>
                                    <div className='note'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex flex-column'>
                                                <div style={{ fontWeight: "500" }}>
                                                    Unit 6 - Bones of Thoracic Cage
                                                </div>
                                                <div style={{ fontWeight: "500" }}>
                                                    Session - 2
                                                </div>
                                            </div>
                                            <div>
                                                29 Aug 2024
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            It consists of three parts: the manubrium, body, and xiphoid process.
                                        </div>
                                        <div className='d-flex align-items-center gap-1 mt-2'>
                                            <div>
                                                <IoMdShareAlt size={20} />
                                            </div>
                                            <div className='pal'></div>
                                            <MdKeyboardArrowDown size={15} />
                                            <div style={{ paddingLeft: "10px", cursor: "pointer" }}><RiDeleteBinLine size={20} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {showAdditionalContent &&
                        <div className='d-flex flex-column border_right'>
                            <div className='d-flex justify-content-between align-items-center px-3 py-3 side_header'>
                                <div className='side_font'>
                                    Additional Content
                                </div>
                                <div className='cursor'>
                                    <IoCloseOutline size={25} onClick={() => { setMainMenu(true); setShowAdditionalContent(false) }} />
                                </div>
                            </div>
                            <div className='height_fea'>
                                <div className='p-2'>
                                    Kalai
                                </div>

                            </div>
                        </div>
                    }
                </Col>
                <Col sm={8} md={9}>
                    <div className='d-flex align-items-center justify-content-between py-2'>
                        <div className='d-flex align-items-center gap-4'>
                            <div>
                                <TbAppsFilled className='icon_size cursor' />
                            </div>
                            <div className='cursor'>
                                {/* <IoIosSearch className='icon_size' /> */}
                                <SearchIcon
                                    width={25}
                                    height={25}
                                />
                            </div>
                        </div>

                        <div className='d-flex flex-column'>
                            <div className='subheading_name'>
                                {/* Clinical Anatomy */}
                                Home {">"} Courses {">"} MBBS/MD (Institutional Access Outside India) {">"} Anatomy {">"} Anatomical Terminology
                            </div>
                            <div className='heading_name'>
                                Clinical Anatomy
                            </div>
                        </div>
                        <div className='d-flex align-items-center gap-5'>
                            <div className='d-flex align-items-center gap-4'>
                                <div onClick={() => {
                                    setMainMenu(false);
                                    setShowClarify(true);
                                    setShowNotes(false);
                                    setShowHyperlink(false);
                                    setShowHighlight(false);
                                    setShowAdditionalContent(false);
                                }}
                                    className='cursor'>
                                    <KlarifyIcon
                                        width={25}
                                        height={25}
                                        color={"#252525"}
                                    />
                                </div>
                                <div>
                                    <FiBookmark className='icon_size cursor' />
                                    {/* <BookmarkIcon
                                        width={25}
                                        height={25}
                                    /> */}
                                </div>
                                <div onClick={() => {
                                    setMainMenu(false);
                                    setShowNotes(true);
                                    setShowHyperlink(false);
                                    setShowHighlight(false);
                                    setShowClarify(false);
                                    setShowAdditionalContent(false);

                                }}>
                                    <CgNotes className='icon_size cursor' />
                                    {/* <NotesIcon
                                        width={25}
                                        height={25}
                                    /> */}
                                </div>
                                <div onClick={() => {
                                    setMainMenu(false);
                                    setShowHyperlink(true);
                                    setShowHighlight(false);
                                    setShowClarify(false)
                                    setShowNotes(false);
                                    setShowAdditionalContent(false);
                                }}
                                    className='cursor'>
                                    {/* <HiMiniLink className='icon_size' /> */}
                                    <HyperlinkIcon
                                        width={25}
                                        height={25}
                                    />
                                </div>
                                <div onClick={() => {
                                    setMainMenu(false);
                                    setShowHighlight(true);
                                    setShowClarify(false)
                                    setShowNotes(false);
                                    setShowHyperlink(false);
                                    setShowAdditionalContent(false);
                                }}
                                    className='cursor'>
                                    <HighlightIcon
                                        width={25}
                                        height={25}
                                    />
                                </div>
                                <div>
                                    <RiSettings5Fill className='icon_size cursor' />
                                    {/* <SettingsIcon
                                        width={25}
                                        height={25}
                                    /> */}
                                </div>
                            </div>
                            <div>
                                <select className='p-2 select_align'>
                                    <option>English</option>
                                    <option>Tamil</option>
                                    <option>Telugu</option>
                                    <option>Malayalam</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className='border_all px-4'>
                        <div className='d-flex align-items-center justify-content-between py-3'>
                            <div>
                                <div className='title_font'>Thorax - Bones of Thoracic Cage</div>
                                <div className='subheading_name'>Last Updated : 25 August 2024
                                    &nbsp; <span className='time_icon'><IoMdTime size={18} /> 2:30 Hrs</span>
                                </div>
                            </div>
                            <div className='index_border d-flex align-items-center gap-2'
                                // onClick={() => setShowIndex(!showIndex)}
                                onClick={handleShow}>
                                <div>
                                    <IoList size={25} />
                                </div>
                                <div>
                                    Index
                                </div>
                            </div>
                        </div>


                        <div>
                            <Accordion
                                activeKey={isActive?.toString()} onSelect={handleMenuClick}
                            >
                                <Accordion.Item eventKey="0"  >
                                    <Accordion.Header>
                                        <FaVideo size={20} /> &nbsp; &nbsp;<span>Videos</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='title_font'>
                                                Clinical Anatomy
                                            </div>
                                            <div>
                                                <select className='p-2 select_align'>
                                                    <option>2D Videos</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div>                                            
                                            <video className='w-100'
                                                ref={videoRef}
                                                loop                                                
                                                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                                muted>
                                                <source src="https://s3.ap-south-1.amazonaws.com/katbook1.0/624296a2143cf32e7078542d/86817948865_the-thoracic-cage.mp4" type="video/webm" />
                                            </video>
                                        </div>

                                        <div className='d-flex align-items-center gap-4 pt-4'>
                                            <div className='video_heading' onClick={() => playSegment(0, 60)}>1. The Axial Skeleton</div>
                                            <div className='video_heading' onClick={() => playSegment(60, 120)}>2. Anatomical Position</div>
                                            <div className='video_heading' onClick={() => playSegment(120, 180)}>3. Thoracic Vertebrae</div>
                                            <div className='video_heading' onClick={() => playSegment(180, 240)}>4. Thoracic Cage</div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <ImBook size={20} /> &nbsp; &nbsp;<span className='accordion_font'>Lecture Notes</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                            <div className='d-flex flex-column gap-2'>
                                                <div style={{ fontSize: "20px !important" }} className='heading_name'>
                                                    Bookmark
                                                </div>
                                                {/* <div className='title_font'>
                                                    Thorax
                                                </div> */}
                                            </div>
                                            <div className='title_font d-flex align-items-center gap-1 cursor'>
                                                <div>
                                                    <MdOutlineExpand size={23} />
                                                </div>
                                                Expand All
                                            </div>
                                        </div>
                                        <Accordion defaultActiveKey="1">
                                            <Accordion.Item eventKey="1-1">
                                                <Accordion.Header>Bones of Thoracic Cage</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='d-flex align-items-center gap-1 mb-3'>
                                                        <div className='book_back'>
                                                            <FiBookmark size={20} />
                                                        </div>
                                                        <div >Bookmark Page</div>
                                                    </div>

                                                    <Accordion defaultActiveKey="1">
                                                        <Accordion.Item eventKey="1-1-1">
                                                            <Accordion.Header>Thoracic Cage</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='py-2' style={{ fontSize: "26px" }}>
                                                                    <u>Introduction:</u>
                                                                </div>

                                                                <div>
                                                                    <ul>
                                                                        <li style={{ fontSize: "20px" }}>Thoracic Cage is a <span className='cursor' onClick={() => {
                                                                            setMainMenu(false);
                                                                            setShowClarify(true);
                                                                            setShowNotes(false);
                                                                            setShowHyperlink(false);
                                                                            setShowHighlight(false);
                                                                            setShowAdditionalContent(false);
                                                                        }}><img src={clarify} alt='' /><span className='sk1'>skeletal framework</span></span> that supports the thorax.</li>
                                                                        <li style={{ fontSize: "20px" }}>Muscles of the thorax, upper extremities, back, and diaphragm are given connection by this cage. </li>
                                                                        <li style={{ fontSize: "20px" }}>Its nature is osteocartilaginous and elastic.</li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li style={{ fontSize: "20px" }}>It has a significant role play in raising or reducing the intrathoracic pressure to ensure a crucial mechanism of respiration i.e. the air is sucked into the lungs during inspiration and expelled from the lungs during the expiration-a crucial mechanism of respiration.</li>
                                                                        <li style={{ fontSize: "20px" }}>The <span className='cursor' onClick={() => {
                                                                            setMainMenu(false);
                                                                            setShowNotes(true);
                                                                            setShowHyperlink(false);
                                                                            setShowHighlight(false);
                                                                            setShowClarify(false)
                                                                            setShowAdditionalContent(false);
                                                                        }}><FaNoteSticky size={15} style={{ color: "rgb(51, 119, 255)", cursor: "pointer" }} /> <span style={{ color: "rgba(155, 54, 234, 0.8)", cursor: "pointer" }}> thoracic vertebrae</span></span>, ribs, costal cartilages, and sternum from the
                                                                            thoracic cage.
                                                                        </li>
                                                                        <li style={{ fontSize: "20px" }}>It gives protection to the internal organs of the thoracic cavity and supports the superior trunk, pectoral girdle, and upper limbs.</li>
                                                                    </ul>
                                                                </div>

                                                                <div>
                                                                    <u style={{ fontSize: "22px" }}>Main Parts of Thoracic cage:</u>
                                                                </div>

                                                                <h5 style={{ fontSize: "22px" }}>Sternum</h5>

                                                                <div>
                                                                    <ul>
                                                                        <li style={{ fontSize: "20px" }}>The <span onClick={() => {
                                                                            setMainMenu(false);
                                                                            setShowHyperlink(true);
                                                                            setShowHighlight(false);
                                                                            setShowClarify(false)
                                                                            setShowNotes(false);
                                                                            setShowAdditionalContent(false);
                                                                        }} className='cursor' style={{ color: "dodgerblue" }}><HiMiniLink size={15} />sternum </span>is the elongated bony structure that anchors the anterior thoracic cage. </li>
                                                                        <li style={{ fontSize: "20px" }}><span onClick={() => {
                                                                            setMainMenu(false);
                                                                            setShowHighlight(true);
                                                                            setShowClarify(false)
                                                                            setShowNotes(false);
                                                                            setShowHyperlink(false);
                                                                            setShowAdditionalContent(false);
                                                                        }} className='cursor' style={{ backgroundColor: "rgba(6, 194, 112, 0.8)" }}>It consists of three parts: the manubrium, body, and xiphoid process.</span></li>
                                                                    </ul>
                                                                </div>


                                                                <Carousel
                                                                    responsive={responsive}
                                                                    infinite={true}
                                                                    autoPlay={true}
                                                                    autoPlaySpeed={3000}
                                                                    keyBoardControl={true}
                                                                    customTransition="all .5"
                                                                    transitionDuration={500}
                                                                >
                                                                    <div className='d-flex justify-content-center'>
                                                                        <img
                                                                            src="https://s3.ap-south-1.amazonaws.com/katbookv2.0/assests/624296a2143cf32e7078542d/62429a0b143cf32e707861ac/62429d2ee575c2317c8a438f/Images/601091115964_1.png"
                                                                            alt=""
                                                                            width={700}
                                                                            height={500}
                                                                            id={601623157831}
                                                                        />
                                                                    </div>
                                                                    <div className='d-flex justify-content-center'>
                                                                        <img
                                                                            src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSzqdwMhdNxPotqkcMNVKkn9z0DbbhvpWlo-IFQjvyXp_R-rkbJ"
                                                                            alt=""
                                                                            width={700}
                                                                            height={500}
                                                                            id={838575621070}
                                                                        />
                                                                    </div>
                                                                    <div className='d-flex justify-content-center'>
                                                                        <img
                                                                            src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQPCffIjByQfY084U9qorI4HN91LvXyTLaVlWnWCzIYOLZ-SSuW"
                                                                            alt=""
                                                                            width={700}
                                                                            height={500}
                                                                            id={191971905959}
                                                                        />
                                                                    </div>
                                                                    <div className='d-flex justify-content-center'>
                                                                        <img
                                                                            src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcS2xCn1gfazdRYFt1HRMo-jIzo17ZnD7ddxwE5uxib4eKNgC7ya"
                                                                            alt=""
                                                                            width={700}
                                                                            height={500}
                                                                            id={824573000195}
                                                                        />
                                                                    </div>

                                                                    <p style={{ textAlign: "justify" }} className='d-flex justify-content-center'>
                                                                        <img
                                                                            src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcT5VFo6gxLGW59Mnkq8osL0WNlJq5vEAyZL5m4D2dvtsHhce7FW"
                                                                            alt=""
                                                                            width={700}
                                                                            height={500}
                                                                            id={546530267412}
                                                                        />
                                                                    </p>
                                                                </Carousel>

                                                                <div className='d-flex justify-content-center'>

                                                                </div>

                                                                <p>
                                                                    <span style={{ fontSize: 20, fontFamily: "Inter" }}>
                                                                        <strong>Manubrium:</strong>
                                                                    </span>
                                                                </p>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The manubrium is the wider, superior portion of the sternum.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The top of the manubrium has a shallow, U-shaped border called the
                                                                            jugular (suprasternal) notch.&nbsp;&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            This can be easily felt at the anterior base of the neck, between
                                                                            the medial ends of the clavicles.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The clavicular notch is the shallow depression located on either
                                                                            side at the superior-lateral margins of the manubrium.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            This is the site of the sternoclavicular joint, between the sternum
                                                                            and clavicle. The first ribs also attach to the manubrium.
                                                                        </span>
                                                                    </li>
                                                                </ul>


                                                                <p>
                                                                    <span style={{ fontSize: 20, fontFamily: "Inter" }}>
                                                                        <strong>Body</strong>
                                                                    </span>
                                                                </p>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The elongated, central portion of the sternum is the body.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The manubrium and body join together at the sternal angle, so-called
                                                                            because the junction between these two components is not flat, but
                                                                            forms a slight bend.
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The second rib attaches to the sternum at the sternal angle. Since
                                                                            the first rib is hidden behind the clavicle, the second rib is the
                                                                            highest rib that can be identified by palpation.&nbsp;&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            Thus, the sternal angle and second rib are important landmarks for
                                                                            the identification and counting of the lower ribs.&nbsp;&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            Ribs 3–7 attach to the sternal body.
                                                                        </span>
                                                                        <span style={{ fontFamily: "Inter", fontSize: 18 }}>
                                                                            &nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>

                                                                <p style={{ textAlign: "justify" }}>
                                                                    <span style={{ fontSize: 20, fontFamily: "Inter" }}>
                                                                        <strong>Xiphoid process</strong>
                                                                    </span>
                                                                </p>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The inferior tip of the sternum is the xiphoid process.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The xiphoid process is the smallest and most variable part of the
                                                                            sternum.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            It is thin, elongated, and lies at the level of the T10
                                                                            vertebra.&nbsp;&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            Although often pointed in some individuals, the process may be
                                                                            blunt, bifid, curved, or deflected to one side or
                                                                            anteriorly.&nbsp;&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The xiphoid process is small and cartilaginous in young people but
                                                                            gets ossified in adults older than age 40, with severe pains
                                                                            accompanying the process of ossification.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            Ossification of the xiphoid process in elderly people may also cause
                                                                            fusion of the xiphoid process with the sternal body.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The xiphoid process is an important landmark in the median plane,
                                                                            indicating the inferior limit of the central part of the thoracic
                                                                            cavity.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontFamily: "Inter", fontSize: 18 }}>
                                                                            This inferior limit corresponds to the xiphisternal joint, and it is
                                                                            also the site of the infrasternal angle (subcostal angle) of the
                                                                            inferior thoracic aperture. Additionally, the xiphoid process is a
                                                                            midline marker for the superior limit of the liver, the central
                                                                            tendon of the diaphragm, and the inferior border of the heart.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <p>

                                                                </p>
                                                                <p>
                                                                    <span style={{ fontSize: 20, fontFamily: "Inter" }}>
                                                                        <strong>Ribs</strong>
                                                                    </span>
                                                                </p>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            Each rib is a curved, flattened bone that contributes to the wall of
                                                                            the thorax.&nbsp;&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The ribs articulate posteriorly with the T1–T12 thoracic vertebrae,
                                                                            and most attach anteriorly via their costal cartilages to the
                                                                            sternum.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontFamily: "Inter", fontSize: 18 }}>
                                                                            There are 12 pairs of ribs. The ribs are numbered 1–12 by the
                                                                            thoracic vertebrae.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>

                                                                <p>
                                                                    <span style={{ fontSize: 20, fontFamily: "Inter" }}>
                                                                        <strong>Thoracic vertebrae</strong>
                                                                    </span>
                                                                </p>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The thoracic vertebrae are a group of 12 small bones that form the
                                                                            vertebral spine of the thorax.&nbsp;&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }} className='cursor' onClick={() => {
                                                                            setMainMenu(false);
                                                                            setShowClarify(true);
                                                                            setShowNotes(false);
                                                                            setShowHyperlink(false);
                                                                            setShowHighlight(false);
                                                                            setShowAdditionalContent(false);
                                                                        }}>
                                                                            <img src={clarify} alt='' /><span className='sk1'>They are intermediate in size between those of the cervical region
                                                                                and lumbar region, to which they are also interposed, and increase
                                                                                in size from above downwards.</span>&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            They are the most typical vertebrae in that they are independent,
                                                                            have bodies, vertebral arches, and seven processes for muscular and
                                                                            articular connections.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            Most of them also have costal facets on their transverse processes
                                                                            for articulation with the tubercles of ribs.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            They are also characterized by bilateral costal facets (demifacets)
                                                                            on their bodies, and long, inferiorly slanting spinous processes.
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            Atypical thoracic vertebrae have “whole costal facets” in place of
                                                                            demifacets.&nbsp;&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The T1 vertebrae, T10, T11, and T12 are all atypical, having only
                                                                            single whole costal facets.
                                                                        </span>
                                                                    </li>
                                                                </ul>


                                                                <p>
                                                                    <span style={{ fontSize: 20, fontFamily: "Inter" }}>
                                                                        <strong>Intercostal spaces</strong>
                                                                    </span>
                                                                </p>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The bones forming the thoracic cage are arranged in a pattern that
                                                                            allows some space between them. Those spaces are referred to as
                                                                            intercostal spaces.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The intercostal spaces separate the ribs and their costal cartilages
                                                                            from one another and allow smooth expansion of the cage during
                                                                            inspiration.
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            The spaces are named according to the rib forming the superior
                                                                            border of the space, for example, the 4th intercostal space lies
                                                                            between the 4th rib and 5th rib; therefore, there are 11 intercostal
                                                                            spaces in the rib cage.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            Intercostal spaces are occupied by intercostal muscles and
                                                                            membranes, 11 intercostal nerves, and two sets (main and collateral)
                                                                            of intercostal blood vessels also identified by the same number
                                                                            assigned to the intercostal space.&nbsp;
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li style={{ textAlign: "justify" }}>
                                                                        <span style={{ fontSize: 18, fontFamily: "Inter" }}>
                                                                            Below the 12th rib, is referred to as the subcostal space and the
                                                                            anterior ramus of the spinal nerve T12 runs through this space, and
                                                                            it is thus referred to as the subcostal nerve.
                                                                        </span>
                                                                    </li>
                                                                </ul>


                                                                <div className='d-flex justify-content-center'>

                                                                </div>

                                                                <div id={2} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="mob-display">
                                                                            <div
                                                                                className="bookM_func_OPQ_addit"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    padding: 20,
                                                                                    justifyContent: "flex-end"
                                                                                }}
                                                                            >
                                                                                <div className="bookM_unchecked">
                                                                                    <img
                                                                                        className="pointer"
                                                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                        alt=""
                                                                                        style={{ width: 14 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontSize: 22,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Formation of the thoracic cage:</strong>
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Anteriorly:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 18,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Anteriorly: by sternum (breastbone).&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>


                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Posteriorly:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 18,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Posteriorly: by 12 thoracic vertebrae and intervening intervertebral
                                                                                        disks.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Laterally:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 18,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Laterally: by 12 pairs of ribs and related 12 pairs of each side:
                                                                                        costal cartilages.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 18,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The rib cage is composed of the sternum, costal cartilages, and ribs
                                                                                        connected to the thoracic vertebrae.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>The ribs joint is as follows:</strong>
                                                                                </span>
                                                                            </p>

                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>
                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Posteriorly</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 18,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        All the ribs joint together with the thoracic vertebrae.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Anteriorly</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 18,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The upper 7 ribs (lst-7th) joint with the side of the sternum via
                                                                                        their costal cartilages.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 18,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The next 3 ribs (example, 8th, 9th, and 10th) joint with every other
                                                                                        via their costal cartilages.&nbsp;&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 18,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The lower 2 ribs (example, 11th, and 12th) don’t joint and the
                                                                                        anterior ends of their costal cartilages are free.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 18,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The costal cartilages of the 7th, 8th, 9th, and 10th ribs create a
                                                                                        sloping costal margin
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={3} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="mob-display">
                                                                            <div
                                                                                className="bookM_func_OPQ_addit"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    padding: 20,
                                                                                    justifyContent: "flex-end"
                                                                                }}
                                                                            >
                                                                                <div className="bookM_unchecked">
                                                                                    <img
                                                                                        className="pointer"
                                                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                        alt=""
                                                                                        style={{ width: 14 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontSize: 22,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Shape Of Thoracic Cage:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The thoracic cage resembles a truncated cone with its narrow end
                                                                                        above and broad end below.&nbsp;&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The narrow upper end is constant above the root of the neck from
                                                                                        which it’s partly separated on either side by the suprapleural
                                                                                        membranes.&nbsp;&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The broad lower end is split from the abdominal cavity by the
                                                                                        diaphragm but gives passage to structures like the aorta, esophagus,
                                                                                        and inferior vena cava.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>

                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The diaphragm is dome-shaped with its convexity pointed
                                                                                        upwards.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Therefore, the upper abdominal viscera is located inside the
                                                                                        thoracic cage and is shielded by it.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        In life, the upper end of the thorax seems broad as a result of the
                                                                                        presence of a shoulder girdle created from clavicles and scapulae,
                                                                                        and related scapular musculature.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The thoracic cavity is truly considerably smaller than one presumes
                                                                                        because the upper narrow part of the thoracic cage seems broad (vide
                                                                                        supra) and the lower broad part of the thoracic cage is encroached
                                                                                        by the abdominal viscera because of the dome-shaped diaphragm.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Joints</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The joints forming the domed-shaped thoracic cage include the:
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Costochondral joints</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The costochondral joints are the articulations between each rib and
                                                                                        its costal cartilage. &nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It is a hyaline cartilaginous type of joint. The articulation is
                                                                                        between the cup-shaped depression in the sternal end of a rib and
                                                                                        the lateral end of the costal cartilage.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The rib and its cartilage are firmly bound together by the
                                                                                        continuity of the periosteum of the rib with the perichondrium of
                                                                                        the cartilage.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        No movement normally occurs at these joints.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Interchondral joints</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The interchondral joints are plane synovial joints between the
                                                                                        adjacent borders of the 6th and 7th, 7th and 8th, and 8th and 9th
                                                                                        costal cartilages.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The joints are usually strengthened by interchondral ligaments, and
                                                                                        they also have synovial cavities that are enclosed by joint
                                                                                        capsules.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Sternocostal joints</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        These are joints formed by the lateral borders of the sternum and
                                                                                        the costal cartilages of the 1st to 7th ribs, and sometimes the 8th
                                                                                        rib.&nbsp;&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The first pair of costal cartilages articulate with the manubrium by
                                                                                        means of a thin dense layer of tightly adherent fibrocartilage
                                                                                        interposed between cartilage and the manubrium, the synchondrosis of
                                                                                        the first rib.&nbsp;&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The second to seventh pairs of costal cartilages articulate with the
                                                                                        sternum at synovial joints with fibrocartilaginous articular
                                                                                        surfaces on both the chondral and sternal aspects, allowing movement
                                                                                        during respiration.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The sternocostal joints are also referred to as sternochondral
                                                                                        joints.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Costovertebral joints</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Articulation of the left and right ribs with the vertebral column
                                                                                        (thoracic vertebrae) complete the domed birdcage-like thoracic cage
                                                                                        posteriorly.&nbsp;&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The costovertebral joints are synovial joints, and they are richly
                                                                                        surrounded by a joint capsule.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Of all the joints of the rib cage, these joints have the largest
                                                                                        amount of ligaments crossing and stabilizing them.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Most of the ribs are attached firmly to the intervertebral (IV)
                                                                                        discs by intra-articular ligament within the joints.&nbsp;&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Fanning around from the anterior margin of the heads of the ribs to
                                                                                        the sides of the bodies of vertebrae and the IV discs between them
                                                                                        is a radiate sternocostal ligament.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Also crossing these joints is the costotransverse ligament passing
                                                                                        from the neck of the rib to the transverse process, and a lateral
                                                                                        costotransverse ligament passing from the tubercle of the rib to the
                                                                                        tip of the transverse process.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        These ligaments strengthen the anterior and posterior aspects of the
                                                                                        joints respectively.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: "14pt",
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        There is a superior costotransverse ligament which may be divided
                                                                                        into a strong anterior costotransverse ligament and a weak posterior
                                                                                        costotransverse ligament, both joining the crests of the neck of the
                                                                                        ribs to the transverse processes superior to each of the ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='d-flex justify-content-center mb-3'>
                                                                    <ReactPlayer
                                                                        url='https://s3.ap-south-1.amazonaws.com/katbook1.0/624296a2143cf32e7078542d/86817948865_the-thoracic-cage.mp4'
                                                                        controls={true}
                                                                    />
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="1-1-2">
                                                            <Accordion.Header>Thoracic Vertebrae</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div style={{ textDecoration: "underline" }}>
                                                                    <h4>Features of the thoracic vertebrae:</h4>
                                                                </div>

                                                                <div>
                                                                    <ul style={{ fontSize: "20px" }}>
                                                                        <li>Thoracic vertebrae include the presence of facets on the sides of the bodies for articulation with the heads of the ribs, and facets on the transverse processes of all, except the 11th and 12th vertebrae, for articulation with the tubercles of the ribs. In addition, the spinous processes are relatively more pointed than other vertebrae and angled sharply downward.
                                                                        </li>
                                                                        <li>The body of a thoracic vertebra is somewhat “heart-shaped,” and is larger than the cervical but smaller than the lumbar vertebrae in size. The body also has small, smooth, and somewhat concave costal facets for the attachment of the ribs.
                                                                        </li>
                                                                        <li>Ribs are generally inserted between two vertebrae, such that each vertebra contributes to articulating with half of the articular surface. Each vertebra, therefore, has a pair of superior articular facets that face posteriorly and a pair of inferior articulating facets that face anteriorly (except for T12).
                                                                        </li>
                                                                        <li>This means that the rib will articulate with the inferior costal facet of the upper vertebrae and the superior costal facet of the lower vertebrae. Transverse processes arise from the arch found behind the superior articular processes and pedicles and are thick and strong with a clubbed end and a small concave surface for the articulation with the tubercle of a rib. These processes are directed obliquely backward towards the spinous process and lateralward.
                                                                        </li>
                                                                        <li>It is important to note that the superior and inferior facets are named relative to their position on the vertebral body, and not for which part of the articular they provide for the rib.
                                                                        </li>
                                                                        <li>The costal facets are also slightly different between the vertebrae. Vertebrae T1, T10, T11, and T12 have complete costal facets on the bodies for ribs 1 and 10-12, which articulate on the vertebral bodies instead of between vertebrae. On the other hand, vertebrae T11 and T12 have no transverse costal facets.
                                                                        </li>
                                                                        <li>Similarly, ribs 11 and 12 articulate with the bodies of T11 and T12, but since the ribs do not have tubercles they also cannot attach to the non-existent transverse costal facets of T11 and T12. These ribs also lack a cartilaginous connection to the sternum and higher costal cartilages and are only embedded in the lumbar muscle, therefore, T11 and T12 are known as the floating ribs.
                                                                        </li>
                                                                        <li>Superior articular processes are thin plates of bone, which project upward from the junctions of the pedicles and laminae, with facets that are nearly flat and directed backward, lateralward, and upward.
                                                                        </li>
                                                                        <li>Inferior articular processes are mostly fused with the laminae, with the facts directed forward, medialward, and downward. The lamina of the thoracic vertebrae are broad, thick, and imbricated (which means their adjacent sides overlap similar to roof tiles or scales of a fish); the lamina function to connect with the pedicles to surround and protect the spinal cord. The spinal cord runs through the vertebral foramen of a vertebra where is it thus protected since it is encased throughout the vertebrae. The nerve roots from the spinal cord exit from the intervertebral foramen, where there are two at each intervertebral level, one for each the right and left side.
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div style={{ textDecoration: "underline" }}>
                                                                    <h4>Introduction</h4>
                                                                </div>

                                                                <div>
                                                                    <ul style={{ fontSize: "20px" }}>
                                                                        <li>Thoracic vertebrae are unique among the bones of the spine in that they are the only vertebrae that support ribs and have overlapping spinous processes.</li>
                                                                        <li>Like all other vertebrae, the thoracic vertebrae help to support the weight of the upper body and protect the delicate spinal cord as it runs through the vertebral canal.
                                                                        </li>
                                                                        <li>The thoracic vertebrae are located in the thorax posterior and medial to the ribs. They form the region of the spinal column inferior to the cervical vertebrae of the neck and superior to the lumbar vertebrae of the lower back.
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div>
                                                                    <ul style={{ fontSize: "20px" }}>
                                                                        <li>
                                                                            Each thoracic vertebra is named for its position within the spine, from the first thoracic vertebra (T1) on the superior end to the twelfth thoracic vertebra (T12) on the inferior end.
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div>
                                                                    <ul style={{ fontSize: "20px" }}>
                                                                        <li>The spinal column formed by the thoracic vertebrae protrudes posteriorly to form the convex thoracic curve of the spine.
                                                                        </li>
                                                                        <li>Several thoracic vertebrae are 'special' in a way, either in terms of their structure or by providing an important landmark that physicians use to orientate themselves and locate other anatomical structures.
                                                                        </li>
                                                                        <li>Two muscles also interact with those twelve vertebrae, these being the <strong>spinalis</strong> and <strong>longissimus</strong>.
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div className='under'>
                                                                    <h4>Anatomy:</h4>
                                                                </div>

                                                                <div>
                                                                    <ul style={{ fontSize: "20px" }}>
                                                                        <li>The twelve thoracic vertebrae are strong bones that are located in the middle of the vertebral column, sandwiched between the cervical ones above and the lumbar vertebrae below. However, they are various anatomical features that make them quite distinct compared to other groups of vertebrae.
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div>
                                                                    <ul style={{ fontSize: "20px" }}>
                                                                        <li>The vertebrae are separated by intervertebral discs of fibrocartilage, which are flexible cartilage discs located between the bodies of two adjacent vertebrae that allow movement in the spine and have a shock-absorbing or cushioning function as well.
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div>
                                                                    <ul style={{ fontSize: "20px" }}>
                                                                        <li>An intervertebral disc consists of an inner gelatinous <strong>nucleus pulposus</strong> surrounded by a ring of fibrocartilage, the <strong>annulus fibrosus</strong>.
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div>
                                                                    <ul style={{ fontSize: "20px" }}>
                                                                        <li>In total, the adult human body typically has 23 discs, with the first found between cervical vertebrae 2 and 3, and the last one is found between the lumbar vertebra and the sacrum.
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div>
                                                                    <ul style={{ fontSize: "20px" }}>
                                                                        <li>In addition to providing shock resistance and cushioning, the discs also help bind adjacent vertebrae together.
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <h5>Bones</h5>

                                                                <div>
                                                                    <ul style={{ fontSize: "20px" }}>
                                                                        <li>
                                                                            There are 12 thoracic vertebrae (denoted as T1-T12) found in adult humans, and they are situated in between the cervical and lumbar vertebrae, with a general sizing larger than the cervical but smaller than the lumbar vertebrae.
                                                                        </li>
                                                                        <li>For each of the 12 thoracic vertebrae, there is a corresponding pair of ribs attached to them. This is unique since no other vertebrae have ribs attached to them.
                                                                        </li>
                                                                        <li>Thoracic vertebrae increase in size as they descend towards the lumbar vertebrae; this is because the lower vertebrae must be able to support more of the body’s weight when a person is standing due to the effects of gravity.
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div id={3} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />

                                                                        <div className="AddiContent_Text  ">
                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontSize: 22,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Types of Thoracic Vertebra:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The typical thoracic vertebrae are seven in number and atypical
                                                                                        thoracic vertebras are five in number.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Typical thoracic vertebra</strong>
                                                                                </span>
                                                                            </p>

                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The body of a typical thoracic vertebrae is heart-shaped with
                                                                                        roughly the same measurements from side to side and
                                                                                        anteroposteriorly.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        On each side, it bears 2 costal facets (demifacets). The superior
                                                                                        costal facets are larger and placed on the upper border of the body
                                                                                        near the pedicle.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        They articulate with the head of the numerically corresponding rib.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The inferior costal facets are smaller and placed on the lower
                                                                                        border in front of the inferior vertebral notch. They articulate
                                                                                        with the next lower rib.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The vertebral foramen is comparatively small and circular.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>The vertebral arch</strong>
                                                                                </span>
                                                                            </p>

                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The pedicles are directed straight backward. The{" "}
                                                                                        <strong>superior vertebral notch</strong> is shallow, while the
                                                                                        inferior vertebral notch is deep and conspicuous.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The laminae overlap each other from above.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The <strong>superior articular processes</strong> project upwards
                                                                                        from the junction of the pedicles and laminae. The articular facets
                                                                                        are flat and are directed backward and a little laterally and
                                                                                        upwards. This direction permits rotatory movements of the spine.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The <strong>inferior articular processes</strong> are fused to the
                                                                                        laminae. Their articular facets are directed forwards and slightly
                                                                                        downwards and medially.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The transverse processes are large and are directed laterally and
                                                                                        backward from the junction of the pedicles and laminae. The anterior
                                                                                        surface of each process bears a facet near its tip, for articulation
                                                                                        with the tubercile of the corresponding rib. In the upper 6
                                                                                        vertebrae, the costal facets on the transverse processes are concave
                                                                                        and face forwards and laterally. In others, the facets are flat and
                                                                                        face upwards, laterally, and slightly forwards.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The spine is long and is directed downwards and backward. The 5th to
                                                                                        9th spines are the longest, more vertical, and overlap each other.
                                                                                        The upper and lower spines are less oblique in direction.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Attachments on a Typical Thoracic Spine Vertebra</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The upper and lower borders of the body give attachment, in front
                                                                                        and behind respectively, to the anterior and posterior longitudinal
                                                                                        ligaments.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The upper borders and lower parts of the anterior surfaces of the
                                                                                        laminae provide attachment to the ligament Flava.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        <em>The transverse process gives attachment to&nbsp;</em>
                                                                                    </strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    (i)&nbsp; &nbsp; The lateral costotransverse ligament (at the
                                                                                    tip)&nbsp;
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    (ii)&nbsp; &nbsp;The superior costotransverse ligament (lower
                                                                                    border)&nbsp;
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    (iii)&nbsp; The inferior costotransverse ligament (anterior surface)
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    (iv)&nbsp; The intertransverse muscles (to upper and lower borders)
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    (v)&nbsp; &nbsp;The levator costae (posterior surface)
                                                                                </span>
                                                                            </p>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The spines give attachment to the <strong>supraspinous </strong>and{" "}
                                                                                        <strong>interspinous</strong> <strong>ligaments</strong>. They also
                                                                                        give attachment to several muscles including the trapezius, the
                                                                                        rhomboids, the latissimus dorsi, the serrate posterior superior and
                                                                                        inferior, and many deep muscles of the back.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Atypical Thoracic Vertebrae</strong>
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>First Thoracic Vertebra</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The body resembles that of a cervical vertebra. It is broad and not
                                                                                        heart-shaped. Its upper surface is lipped laterally and beveled
                                                                                        anteriorly. The superior costal facet on the body is complete. It
                                                                                        articulates with the head of the first rib. The inferior costal
                                                                                        facet is a “demifacet” for the second rib.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The spine is thick, long, and nearly horizontal.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The superior vertebral notches are well marked, as in cervical
                                                                                        vertebrae.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Ninth Thoracic Vertebra</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It resembles a typical thoracic vertebra except that the body has
                                                                                        only the superior costal facets (demifacets). The inferior costal
                                                                                        facets are absent.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Tenth Thoracic Vertebra</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It resembles a typical thoracic vertebra except that the body has a
                                                                                        single complete superior costal facet on each side, extending onto
                                                                                        the root of the pedicle.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Eleventh Thoracic Vertebrae</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The body has a single large costal facet on each side, extending
                                                                                        onto the upper part of the pedicle.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The transverse process is small and has no articular facet.
                                                                                        Sometimes it is difficult to differentiate between vertebrae T10 and
                                                                                        T11.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Twelfth Thoracic Vertebra</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The shape of the body, pedicles, transverse processes, and spine are
                                                                                        similar to those of a lumbar vertebra. However, the body bears a
                                                                                        single costal facet on each side, which lies more on the lower part
                                                                                        of the pedicle than on the body.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The transverse process is small and has no facet, but has superior,
                                                                                        inferior, and lateral tubercles.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The inferior articular facets are lumbar in type and are directed
                                                                                        laterally (everted), but the superior articular facets are thoracic
                                                                                        in type.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex justify-content-center'>

                                                                </div>
                                                                <div id={4} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="mob-display">
                                                                            <div
                                                                                className="bookM_func_OPQ_addit"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    padding: 20,
                                                                                    justifyContent: "flex-end"
                                                                                }}
                                                                            >
                                                                                <div className="bookM_unchecked">
                                                                                    <img
                                                                                        className="pointer"
                                                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                        alt=""
                                                                                        style={{ width: 14 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)",
                                                                                        fontSize: 20
                                                                                    }}
                                                                                >
                                                                                    <strong>Muscles:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)",
                                                                                        fontSize: 20
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        The muscles of the thoracic spine are arranged in layers.
                                                                                    </strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Those closest to the skin’s surface run from the back of the
                                                                                        vertebrae to the Scapula. Eg. <strong>Trapezius, Rhomboids </strong>
                                                                                        and <strong>Latissimus Dorsi.</strong>
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Others wrap around the rib cage and connect to the shoulder. Eg
                                                                                        Serratus anterior, Serratus posterior, and Pectoralis minor.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Strap-shaped muscles called Erector Spinaeto make up the middle
                                                                                        layer of muscles ( ie the intrinsic back muscles – the{" "}
                                                                                        <strong>iliocostalis, longissimus, and spinalis</strong>).
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Together these muscles form a column, known as the{" "}
                                                                                        <strong>erector spinae.</strong> These muscles run up and down over
                                                                                        the lower ribs and thorax (the rib cage) and cross to the low back.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The deepest layer of muscles attaches along the back of the spine
                                                                                        bones, connecting the vertebrae. The deep intrinsic muscles are
                                                                                        located underneath the erector spinae and are known collectively as
                                                                                        the <strong>transversospinales.&nbsp;</strong>
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        They are a group of short muscles, associated with the transverse
                                                                                        and spinous processes of the vertebral column.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        There are three major muscles in this group – the{" "}
                                                                                        <strong>semispinalis, multifidus</strong>, and{" "}
                                                                                        <strong>rotatores.</strong>
                                                                                        <br />
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The Thoracolumbar Fascia (important to thoracic musculature) is a
                                                                                        large area of connective tissue - roughly diamond-shaped - which
                                                                                        comprises the thoracic and lumbar parts of the deep fascia enclosing
                                                                                        the intrinsic back muscles.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Muscles also connect from one rib to the next. Eg Muscles of
                                                                                        Respiration.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={5} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="mob-display">
                                                                            <div
                                                                                className="bookM_func_OPQ_addit"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    padding: 20,
                                                                                    justifyContent: "flex-end"
                                                                                }}
                                                                            >
                                                                                <div className="bookM_unchecked">
                                                                                    <img
                                                                                        className="pointer"
                                                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                        alt=""
                                                                                        style={{ width: 14 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Individual thoracic vertebrae:</strong>
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>First Thoracic Vertebra (T1):</strong> The first thoracic
                                                                                    vertebra has, on either side of the body, an entire articular facet
                                                                                    for the head of the first rib, and a demi-facet for the upper half of
                                                                                    the head of the second rib. The body is like that of a cervical
                                                                                    vertebra, being broad, concave, and lipped on either side.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Second Thoracic Vertebra (T2): </strong>The thoracic spinal
                                                                                    nerve 2 (T2) passes out underneath it. The second thoracic vertebra is
                                                                                    larger than the first thoracic vertebra.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Third Thoracic Vertebra (T3): </strong>The thoracic spinal
                                                                                    nerve 3 (T3) passes out underneath it.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Fourth Thoracic Vertebra (T4): </strong>The fourth thoracic
                                                                                    vertebra, together with the fifth, is at the same level as the sternal
                                                                                    angle. The thoracic spinal nerve 4 (T4) passes out underneath it.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Fifth Thoracic Vertebra (T5): </strong>The fifth thoracic
                                                                                    vertebra, together with the fourth, is at the same level as the
                                                                                    sternal angle. The human trachea divides into two main bronchi at the
                                                                                    level of the 5th thoracic vertebra, but may also end higher or lower,
                                                                                    depending on breathing.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Sixth Thoracic Vertebra (T6):</strong> The thoracic spinal
                                                                                    nerve 6 (T6) passes out underneath it.
                                                                                </span>
                                                                            </p>
                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Seventh Thoracic Vertebra (T7): </strong>The thoracic spinal
                                                                                    nerve 7 (T7) passes out underneath it.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Eighth Thoracic Vertebra (T8): </strong>The eighth thoracic
                                                                                    vertebra is, together with the ninth thoracic vertebra, at the same
                                                                                    level as the xiphisternum.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Ninth Thoracic Vertebra (T9):</strong> The ninth thoracic
                                                                                    vertebra may have no demi-facets below. In some subjects, however, it
                                                                                    has two demi-facets on either side; when this occurs the tenth doesn’t
                                                                                    have facets but demi-facets at the upper part.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Tenth Thoracic Vertebra (T10): </strong>The tenth thoracic
                                                                                    vertebra has an entire articular facet (not demi-facet) on either
                                                                                    side, which is placed partly on the lateral surface of the pedicle. It
                                                                                    doesn’t have any kind of facet below, because the following ribs only
                                                                                    have one facet on their heads.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Eleventh Thoracic Vertebra (T11): </strong>In the eleventh
                                                                                    thoracic vertebra the body approaches in its form and size to that of
                                                                                    the lumbar vertebrae. The articular facets for the heads of the ribs
                                                                                    are of large size and placed chiefly on the pedicles, which are
                                                                                    thicker and stronger in this and the next vertebra than in any other
                                                                                    part of the thoracic region.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Twelfth Thoracic Vertebra (T12):</strong> The twelfth thoracic
                                                                                    vertebra has the same general characteristics as the eleventh, but may
                                                                                    be distinguished from it by its inferior articular surfaces being
                                                                                    convex and directed lateralward.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    The T1 and T12 vertebrae are the exceptions to this rule; T1 features
                                                                                    a superior articular process resembling those of the cervical
                                                                                    vertebrae, while the inferior articular process of T12 resembles those
                                                                                    of the lumbar vertebrae.
                                                                                </span>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={6} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)",
                                                                                        fontSize: 20
                                                                                    }}
                                                                                >
                                                                                    <strong>Nerves:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    The hollow tube formed by the bony rings on the back of the spinal
                                                                                    column surrounds the spinal cord. The bones of the spinal column
                                                                                    protect the spinal cord.
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        In the thoracic spine, the spinal canal is narrower than in the rest
                                                                                        of the spine, giving very little extra space for the spinal cord as
                                                                                        it passes through the thoracic spine.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Between the vertebrae, two large spinal nerves branch off the spinal
                                                                                        cord, one on the left and one on the right (12 thoracic nerve
                                                                                        pairs).
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The first nerve root exits between the T1 and T2 vertebrae The
                                                                                        nerves pass through the neural foramina of each vertebra. These
                                                                                        spinal nerves group together to form the main nerves that go to the
                                                                                        organs and limbs. The nerves of the thoracic spine mainly control
                                                                                        the muscles and organs of the chest and abdomen.
                                                                                        <br />
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    The 12 pairs of thoracic nerves are derived from the dorsal and
                                                                                    ventral roots of their corresponding segments. These nerves
                                                                                </span>
                                                                            </p>

                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Do not form plexuses; they distribute cutaneous branches to the
                                                                                        thoracic dermatomes and send other sensory fibers to deeper muscular
                                                                                        structures, vessels, periosteum, parietal pleura, the peritoneum,
                                                                                        and breast tissue.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Also send motor fibers to muscles of the thoracic and abdominal wall
                                                                                        and carry{" "}
                                                                                        <strong>
                                                                                            preganglionic and postganglionic sympathetic nerve fibers
                                                                                        </strong>{" "}
                                                                                        into and out of the sympathetic chain.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Muscles of the thoracic and abdominal wall, supplied by these
                                                                                        nerves, act as accessory respiratory muscles and may assist in
                                                                                        breathing in times of dyspnea or phrenic nerve impairment.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        T1 is part of the Brachial plexus
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={7} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        <span style={{ textDecoration: "underline" }}>Clinical notes:</span>
                                                                                    </strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Herniated discs</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        When the intervertebral discs are under heavy stress, such as when
                                                                                        lifting a heavy object, the disc will bulge laterally and excessive
                                                                                        stress will cause the annulus to crack and the nucleus to ooze out
                                                                                        from the cracked space in the annulus fibrosusthis is called a
                                                                                        herniated disc.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>
                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Given the lack of a functional disc between two vertebrae increased
                                                                                        pressure can cause a lot of spinal cord pain, and create a might
                                                                                        higher risk of bone fracturing and damaging the adjacent vertebral
                                                                                        bones since they will rub on each other.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        People are on average roughly 1% shorter in height when they go to
                                                                                        bed at night than when they first wake up in the morning. This is
                                                                                        since the weight of the body compresses the intervertebral discs
                                                                                        during the day as they move about through their usual business, and
                                                                                        the compression on these discs squeezes the water out of them.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        During slumber or times when one is laying down in a supine
                                                                                        position, the weight is no longer focused on the spine, which allows
                                                                                        the discs to reabsorb water and swell, thus restoring a person’s
                                                                                        height.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Scoliosis</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Scoliosis, or curved back, is an abnormal curvature of the vertebral
                                                                                        column characterized by an exaggerated lateral curvature together
                                                                                        with a rotation of the vertebrae.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        In order to be diagnosed, there needs to be at least a 10-degree
                                                                                        angulation of the spine associated with vertebral rotation.&nbsp;
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        There are several types of scoliosis, each one having its own causes
                                                                                        and evolution:
                                                                                    </strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Structural scoliosis</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It is caused by a vertebral development abnormality where half of
                                                                                        the vertebral body is missing entirely (hemivertebra).
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The vast majority of structural scoliosis occurs without any
                                                                                        specific triggers or associated conditions, hence they are
                                                                                        idiopathic. It affects more females than males, and it is also more
                                                                                        severe. It is also common in growing teenagers, after the onset of
                                                                                        puberty.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Myopathic scoliosis</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        As the name suggests, the underlying cause consists of a muscle
                                                                                        problem. More specifically, the intrinsic muscles of the back
                                                                                        (medial and lateral tracts) that are closely associated with the
                                                                                        vertebral column are asymmetrical in strength.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        When this happens, the muscles on one side pull more forcefully
                                                                                        towards, while the opposite ones cannot oppose the pull, hence
                                                                                        curving the vertebral column laterally.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <div className='d-flex justify-content-center'>

                                                                            </div>
                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Functional scoliosis</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        &nbsp;This occurs when the length of the lower limbs is unequal,
                                                                                        resulting in a compensatory movement consisting of a pelvic tilt.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Habit scoliosis</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This is perhaps the bane of modern times, during which everyone sits
                                                                                        down incorrectly for hours on end. As the name suggests, it is
                                                                                        caused by anincorrect posture while sitting, or even standing.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Specific to this type of scoliosis is the fact that it disappears
                                                                                        upon maximum flexion of the vertebral column, so the physician can
                                                                                        take advantage of this aspect when confirming the diagnosis.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        There are a number of management options for scoliosis, depending on
                                                                                        the specific causes, type, age, and severity.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        They include observations, bracing or surgery. While the latter
                                                                                        might sound like the obvious choice, it is only conducted in curves
                                                                                        greater than 45 degrees in immature patients and greater than 50
                                                                                        degrees in mature ones.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Thoracic Radiculopathy - </strong>Thoracic nerve root lesions
                                                                                    account for less than 2% of all radiculopathies and are most commonly
                                                                                    caused by diabetes and thoracic disk herniation.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Osteoporosis</strong> increases the risk for thoracic spinal
                                                                                    fracture. A common type is a vertebral compression fracture (VCF) that
                                                                                    can cause one or more bony bodies to flatten or become wedge-shaped
                                                                                    resulting in the spinal cord and/or nerve compression. Sudden and
                                                                                    acute back pain is associated with a VCF.
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Spinal cord compression</strong> Different types of
                                                                                    scoliosisan abnormal side-to-side curvature of the spineis well known
                                                                                    to develop in the thoracic spine and may cause spinal deformity. eg
                                                                                    Scheuermann’s disease, Posture related
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    Spinal tumors can occur anywhere along the spine, but most occur in
                                                                                    the thoracic (mid-to-upper back) region - About 70% of metastatic
                                                                                    spinal tumors occur in the thoracic region, 20% occur in the lumbar
                                                                                    (lower back) region, fewer occur in the cervical region.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    More than half of spinal cord injuries occur in the cervical area, a
                                                                                    third occur in the thoracic area, and the remainder occurs mostly in
                                                                                    the lumbar region.
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>T4 syndrome or upper thoracic syndrome</strong> was described
                                                                                    as “a pattern that involves upper extremity paraesthesia”. It can be
                                                                                    caused by thoracic hypomobility but can also have a sympathetic
                                                                                    origin.
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    The <strong>Slipping Rib Syndrome</strong> is an infrequent cause of
                                                                                    thoracic and upper abdominal intermittent pain and is thought to arise
                                                                                    from hypermobility of the rib cartilage of the false and floating ribs
                                                                                    (these are the most involved in this syndrome).
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Rib Stress Fracture</strong> is a frequently occurring
                                                                                    pathology in the rower’s community, with an incidence of 6 to 12%.
                                                                                </span>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                        <Accordion.Item eventKey="1-1-3">
                                                            <Accordion.Header>Sternum</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div id={0} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />

                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Anatomy of the Sternum:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The sternum, commonly known as the <strong>breastbone</strong>, is a
                                                                                        long, narrow flat bone that serves as the keystone of the rib cage
                                                                                        and stabilizes the thoracic skeleton.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Several muscles that move the arms, head, and neck have their
                                                                                        origins in the sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It also protects several vital organs of the chest, such as the
                                                                                        heart, aorta, vena cava, and thymus gland that are located just deep
                                                                                        into the sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This includes the lungs, heart, and stomach, along with all of their
                                                                                        intricate blood vessels, muscles, and cartilage.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The sternum also acts as a joining structure to the upper ribs on
                                                                                        either side of the body.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        Measuring around 6 inches, the sternum has three main parts:
                                                                                    </strong>
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Manubrium</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The manubrium is the wide and strong part of the sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It is thick along its superior outline and tapers towards its
                                                                                        inferior side for the articulation with the portion of the sternum
                                                                                        called <strong>mesosternum</strong>.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The superior part has a central suprasternal cleft and lateral oval
                                                                                        surface for the synovial articulation with that of the clavicle.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Small surfaces may be rarely observed on the superior margin between
                                                                                        the suprasternal cleft and clavicular cleft.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        These are labeled as the location for the articulation with the
                                                                                        suprasternal ossicles.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The existence of the ossicles is very changeable and may display the
                                                                                        sternal divisions of cervical ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The lateral portions have the cavity for the articulation with the
                                                                                        first costal cartilage at the upper part and a small demifacet for
                                                                                        articulation with the superior portion of the second costal
                                                                                        cartilage at the lower part.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The anterior region is even and convex from the side and concave
                                                                                        from the upper and lower parts.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It shows the two lateral spaces at the posterior part of the
                                                                                        clavicle via which the pectoralis major muscles develop.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Body</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The body is flat and elongated – the largest part of the sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It articulates with the manubrium superiorly (manubriosternal joint)
                                                                                        and the xiphoid process inferiorly (xiphisternal joint).
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The lateral edges of the body are marked by numerous articular
                                                                                        facets (cartilage lined depressions in the bone).
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        These articular facets articulate with the costal cartilages of ribs
                                                                                        3-6.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        There are smaller facets for articulation with parts of the second
                                                                                        and seventh ribs – known as demifacets.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Xiphoid process</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The xiphoid process is a tiny bone structure located at the center
                                                                                        of the chest, just below the lower part of the sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Xiphoid process is also known as the xiphisternum, is the smallest
                                                                                        part of the three sections of the sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The xiphoid process shape is variable: it may be wide, thin and
                                                                                        elongated, pointed, bifid, curved, or perforated.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Xiphoid process begins as a cartilaginous structure in youth, which
                                                                                        becomes ossified in the adult.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The joints between the body of the sternum and the xiphoid process
                                                                                        are usually symphyses.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The joint between the body of the sternum and the xiphoid process
                                                                                        often becomes ossified with age.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        On each side of its upper lateral margin is a demifacet for
                                                                                        articulation with the inferior end of the seventh costal cartilage.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The sternum as a whole has been compared to an upside-down sword due
                                                                                        to the rectangular part at the top resembling a handle.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The remainder of the sternum is flat and long, similar to the blade
                                                                                        of a sword with a tip looking like the xiphoid process at the end.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Anatomical variations of the sternum include varying sizes of the
                                                                                        sternal angle.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This often has little impact on function or treatment following an
                                                                                        injury but can vary between individuals, like a two-pronged xiphoid
                                                                                        process and the presence of a sternal foramen.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        A sternal foramen is a separation between the two sides of the
                                                                                        sternum and results from poor development.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This foramen appears as a small tunnel in the sternum and can often
                                                                                        be mistaken by doctors as a result of trauma.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={1} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="mob-display">
                                                                            <div
                                                                                className="bookM_func_OPQ_addit"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    padding: 20,
                                                                                    justifyContent: "flex-end"
                                                                                }}
                                                                            >
                                                                                <div className="bookM_unchecked">
                                                                                    <img
                                                                                        className="pointer"
                                                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                        alt=""
                                                                                        style={{ width: 14 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Function:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>The sternum serves two very important functions</strong>
                                                                                </span>
                                                                            </p>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Protection:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Sternum, along withribs, works to protect the organs of the torso,
                                                                                        such as the heart, lungs, and chest blood vessels.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Support:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Sternum also provides a connection point for other parts of the
                                                                                        skeletal system, including the collarbone and most ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Some muscles of the chest and upper abdomen also connect to the
                                                                                        sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The sternum does not assist with any visible range of motion to the
                                                                                        chest or torso.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        However, cartilaginous connectors between the sternum and each of
                                                                                        the upper six ribs assist with minor motions that occur with each
                                                                                        breath.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Each inward breath requires the lungs to fill with air and the
                                                                                        intercostal (or rib) muscles to contract.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        All of these motions require some internal room to function
                                                                                        appropriately.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={2} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="mob-display">
                                                                            <div
                                                                                className="bookM_func_OPQ_addit"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    padding: 20,
                                                                                    justifyContent: "flex-end"
                                                                                }}
                                                                            >
                                                                                <div className="bookM_unchecked">
                                                                                    <img
                                                                                        className="pointer"
                                                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                        alt=""
                                                                                        style={{ width: 14 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        <span style={{ fontFamily: "Inter" }}>
                                                                                            Causes of Sternal Pain:
                                                                                        </span>
                                                                                    </strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Sternum pain is usually caused by problems with the muscles and
                                                                                        bones near the sternum and not the sternum itself.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Pain felt just behind or below the sternum is called substernal pain
                                                                                        and is sometimes caused by gastrointestinal problems.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>
                                                                                        Some of the most common causes of sternum and substernal pain are
                                                                                    </strong>
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Costochondritis</strong>
                                                                                    <strong>:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Costochondritis is a self-limiting condition defined as painful
                                                                                        chronic inflammation of the costochondral junctions of&nbsp;ribsor
                                                                                        chondrosternal joints of the anterior chest wall.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It is a clinical diagnosis and does not require specific diagnostic
                                                                                        testing in the absence of concomitant cardiopulmonary symptoms or
                                                                                        risk factors.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Costochondritis is often confused with Tietze syndrome.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Palpation of the affected chondrosternal joints of the chest wall
                                                                                        elicits tendernessand pain is reproduced by palpation of the
                                                                                        affected cartilage segments which may radiate out into the chest
                                                                                        wall.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Collarbone injuries:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The most common symptom of an SC joint disorder is a pain in the
                                                                                        area where the clavicle meets the sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This pain will be present with a sprain but will be much sharper in
                                                                                        the case of a fracture or dislocationespecially when attempting to
                                                                                        move the arm.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Other signs and symptoms may include:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Swelling, bruising, or tenderness over the joint
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        A crunching or grinding sound when trying to move the arm
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Limited range of motion in the arm
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        With an inflammatory condition, such as rheumatoid arthritis, may
                                                                                        have simultaneous pain in other joints in the body
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        With a joint infection, there may be redness over the joint andmay
                                                                                        have fever, chills, or night sweats.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        If experience any of the symptoms of a joint infection, it is
                                                                                        important to seek medical attention right away<strong>.</strong>
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Sternoclavicular joint injury:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Injuries to the SC joint can range from a mild sprain, in which the
                                                                                        surrounding ligaments are stretched (the most commonly seen injury),
                                                                                        to a fracture of the clavicle (collarbone) itself.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        In rare cases, a strong blow to the shoulder can cause an injury in
                                                                                        which the joint dislocates completely from its normal position.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Joint dislocations are classified as either"
                                                                                        <strong>anterior"</strong> or "<strong>posterior</strong>,"
                                                                                        depending on the direction in which the collarbone is pushed during
                                                                                        the injury.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Epigastric hernia:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        An epigastric hernia usually causes a bump to occur in the area
                                                                                        below the sternum, or breastbone, and above the belly button.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This bump is caused by a mass of fat that has pushed through the
                                                                                        hernia.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The raised area may be visible all the time or only when coughing,
                                                                                        sneezing, or laughing.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This bump, or mass, can grow and become larger in some cases.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        can have more than one epigastric hernia at a time.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        An epigastric hernia can also cause tenderness and pain in the
                                                                                        epigastric region.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        However, it’s common for an epigastric hernia not to show any
                                                                                        symptoms.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Sternal fractures:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Most commonly caused by blunt, anterior chest-wall trauma and
                                                                                        deceleration injuries, with a reported incidence of 3% to 6.8% in
                                                                                        motor vehicle collisions.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Athletic injuries falls, and assaults are the frequent causes of the
                                                                                        remaining cases.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Sternal fractures are frequently diagnosed using a lateral chest
                                                                                        x-ray or CT scan of the chest.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Sternal fractures increase the risk of and are commonly associated
                                                                                        with other injuries.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The disposition of patients with a sternal fracture is dependent
                                                                                        upon several variables, including potentially significant associated
                                                                                        injuries, comorbidities, and inadequate pain control
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Acid reflux:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Stomach acid refluxes up into the esophagus and causes pain.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This pain can be felt as a burning sensation behind the sternum or
                                                                                        breastbone, either as a spasm or a sharp pain.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Many times the pain of acid reflux can be mistaken for the pain of a
                                                                                        heart attack.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The pain of acid reflux (heartburn) can remain in the lower chest or
                                                                                        it can radiate to the back of the throat and be associated with
                                                                                        waterbrash, a sour taste in the back of the throat
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Muscular strain or bruise:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Many people with chest pain describe feeling like their sternum is
                                                                                        bruised.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The sternum is more commonly called the breastbone.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        While it’s possible to bruise the sternum, this pain is more likely
                                                                                        caused by costochondritis.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This is inflammation of the cartilage that connects ribs tothe
                                                                                        sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Other symptoms of a bruised sternum include:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Discoloration of chest skin
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Tenderness
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Swelling
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Stiffness
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex justify-content-center'>
                                                                    <ReactPlayer
                                                                        url='https://s3.ap-south-1.amazonaws.com/katbook1.0/624296a2143cf32e7078542d/621622898699_the-anatomy-of-sternum.mp4'
                                                                        controls={true}
                                                                    />
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                        <Accordion.Item eventKey="1-1-4">
                                                            <Accordion.Header>Ribs</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div id={0} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Introduction:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The ribs are curved, flat bones that form the majority of the
                                                                                        thoracic cage.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        They are extremely light, but highly resilient; contributing to
                                                                                        their role in protecting the internal thoracic organs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        There are twelve pairs of ribs, all of which articulate with the
                                                                                        vertebral column.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        However, only seven have a direct articulation with the sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        As such, ribs can be allocated to one of three distinct types;{" "}
                                                                                        <strong>
                                                                                            true (vertebrosternal) ribs, false (vertebrochondral) ribs, and
                                                                                            floating (vertebral, free) ribs
                                                                                        </strong>
                                                                                        .
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Ribs one to seven are considered true ribs and attach directly to
                                                                                        the sternum via their costal cartilage.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Ribs eight to ten are the false ribs and are connected to the
                                                                                        sternum indirectly via the cartilage of the rib above them.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The final two pairs of ribs are floating ribs and the cartilage of
                                                                                        these ribs tends to end within the abdominal musculature
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={1} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="mob-display">
                                                                            <div
                                                                                className="bookM_func_OPQ_addit"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    padding: 20,
                                                                                    justifyContent: "flex-end"
                                                                                }}
                                                                            >
                                                                                <div className="bookM_unchecked">
                                                                                    <img
                                                                                        className="pointer"
                                                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                        alt=""
                                                                                        style={{ width: 14 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Rib Structure:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        There are two classifications of ribs
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    The typical ribs have a <strong>generalized structure</strong>, while
                                                                                    the atypical ribs have <strong>variations of this structure</strong>.
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Typical Ribs</strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>The typical rib consists of a head, neck, and body:</strong>
                                                                                </span>
                                                                            </p>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Head </strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The head is wedge-shaped and has two articular facets separated by a
                                                                                        wedge of bone.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        One facet articulates with the numerically corresponding vertebra,
                                                                                        and the other articulates with the vertebra above.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Neck</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The neck contains no bony prominences, but simply connects the head
                                                                                        with the body.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Where the neck meets the body there is a roughed tubercle, with a
                                                                                        facet for articulation with the transverse process of the
                                                                                        corresponding vertebra.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Body</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The body or shaft of the rib is flat and curved.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The internal surface of the shaft has a groove for the neurovascular
                                                                                        supply of the thorax, protecting the vessels and nerves from damage.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Atypical Ribs</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Ribs 1, 2, 10 11, and 12 can be described as ‘atypical’ – they have
                                                                                        features that are not common to all ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Rib 1 is shorter and wider than the other ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It only has one facet on its head for articulation with its
                                                                                        corresponding vertebra.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The superior surface is marked by two grooves, which make way for
                                                                                        the subclavian vessels.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Rib 2 is thinner and longer than rib 1 and has two articular facets
                                                                                        on the head as normal.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It has a roughened area on its upper surface, from which the
                                                                                        serratus anterior muscle originates.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Rib 10 only has one facet – for articulation with its numerically
                                                                                        corresponding vertebra.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Ribs 11 and 12 have no neck, and only contain one facet, which is
                                                                                        for articulation with their corresponding vertebra.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={2} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="mob-display">
                                                                            <div
                                                                                className="bookM_func_OPQ_addit"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    padding: 20,
                                                                                    justifyContent: "flex-end"
                                                                                }}
                                                                            >
                                                                                <div className="bookM_unchecked">
                                                                                    <img
                                                                                        className="pointer"
                                                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                        alt=""
                                                                                        style={{ width: 14 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Articulations:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The majority of the ribs have anterior and posterior articulation.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Posterior</strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    All the twelve ribs articulate posteriorly with the vertebra of the
                                                                                    spine. Each rib forms two joints:
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Costotransverse joint</strong> – Between the tubercle of the
                                                                                        rib, and the transverse costal facet of the corresponding vertebra.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Costovertebral joint</strong> – Between the head of the rib,
                                                                                        the superior costal facet of the corresponding vertebra, and the
                                                                                        inferior costal facet of the vertebra above
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontFamily: "Inter",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Anterior</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The anterior attachment of the ribs vary:
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Ribs 1-7 attach independently to the sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Ribs 8 – 10 attach to the costal cartilages superior to them.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Ribs 11 and 12 do not have an anterior attachment and end in the
                                                                                        abdominal musculature. Because of this, they are sometimes called
                                                                                        ‘floating ribs’
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={3} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="mob-display">
                                                                            <div
                                                                                className="bookM_func_OPQ_addit"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    padding: 20,
                                                                                    justifyContent: "flex-end"
                                                                                }}
                                                                            >
                                                                                <div className="bookM_unchecked">
                                                                                    <img
                                                                                        className="pointer"
                                                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                        alt=""
                                                                                        style={{ width: 14 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="AddiContent_Text  ">
                                                                            <p>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontSize: 20,
                                                                                        color: "rgb(53, 65, 77)",
                                                                                        fontFamily: "Inter"
                                                                                    }}
                                                                                >
                                                                                    <strong>Associated Conditions:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)",
                                                                                        fontFamily: "Inter"
                                                                                    }}
                                                                                >
                                                                                    <strong>Several conditions may affectribs. These may include:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)",
                                                                                        fontFamily: "Inter"
                                                                                    }}
                                                                                >
                                                                                    <strong>Rib fracture: </strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)",
                                                                                            fontFamily: "Inter"
                                                                                        }}
                                                                                    >
                                                                                        Severe trauma to a rib may cause it to break, leading to pain,
                                                                                        difficulty moving, and difficulty breathing.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)",
                                                                                        fontFamily: "Inter"
                                                                                    }}
                                                                                >
                                                                                    <strong>Costochondritis:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)",
                                                                                            fontFamily: "Inter"
                                                                                        }}
                                                                                    >
                                                                                        Inflammation to the cartilage that attaches ribs to the sternum may
                                                                                        lead to pain and difficulty breathing and maintaining certain
                                                                                        positions.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)",
                                                                                        fontFamily: "Inter"
                                                                                    }}
                                                                                >
                                                                                    <strong>Rib dislocation or subluxation: </strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)",
                                                                                            fontFamily: "Inter"
                                                                                        }}
                                                                                    >
                                                                                        A dislocated rib occurs as the result of trauma, leading to pain,
                                                                                        difficulty moving, and problems with breathing.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)",
                                                                                            fontFamily: "Inter"
                                                                                        }}
                                                                                    >
                                                                                        The dislocation occurs at the facet joint where the rib attaches to
                                                                                        the vertebrae.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)",
                                                                                            fontFamily: "Inter"
                                                                                        }}
                                                                                    >
                                                                                        This may result in a feeling of muscle spasm in the back.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <ul>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)",
                                                                                            fontFamily: "Inter"
                                                                                        }}
                                                                                    >
                                                                                        A subluxation is when the rib slips out of place but isn't fully
                                                                                        dislocated.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)",
                                                                                        fontFamily: "Inter"
                                                                                    }}
                                                                                >
                                                                                    <strong>Osteoporosis:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)",
                                                                                            fontFamily: "Inter"
                                                                                        }}
                                                                                    >
                                                                                        Osteoporosis is bone weakening, often leading to an increased risk
                                                                                        of rib fractures as the result of a fall.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        color: "rgb(33, 37, 41)",
                                                                                        fontFamily: "Inter"
                                                                                    }}
                                                                                >
                                                                                    <strong>Tumor:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)",
                                                                                            fontFamily: "Inter"
                                                                                        }}
                                                                                    >
                                                                                        Rib tumors are rare, but the insidious onset of pain may indicate
                                                                                        that a benign or malignant tumor is present in a rib.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div id={4} className="book padding-0 col">
                                                                    <div className="" style={{ zoom: "100%" }}>
                                                                        <div
                                                                            className="root_container_display"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                marginTop: 10,
                                                                                marginBottom: 0,
                                                                                justifyContent: "space-between"
                                                                            }}
                                                                        />
                                                                        <div className="mob-display">
                                                                            <div
                                                                                className="bookM_func_OPQ_addit"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "100%",
                                                                                    padding: 20,
                                                                                    justifyContent: "flex-end"
                                                                                }}
                                                                            >
                                                                                <div className="bookM_unchecked">
                                                                                    <img
                                                                                        className="pointer"
                                                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                        alt=""
                                                                                        style={{ width: 14 }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="AddiContent_Text  ">
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(53, 65, 77)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Muscles attachments:</strong>
                                                                                </span>
                                                                            </p>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>External intercostal muscles</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        There are eleven pairs of external intercostal muscles and these are
                                                                                        the most superficial in the area.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        They extend from the lateral border of the costal grooves to the
                                                                                        superior margins of the ribs below.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The fibres of this muscle pass anteroinferior in an oblique manner
                                                                                        andextend around the thoracic wall from the tubercles to the costal
                                                                                        cartilages of the ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        These muscles help to elevate the ribs in inspiration.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Internal intercostal muscles</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The eleven pairs of internal intercostal muscles are found posterior
                                                                                        to the external intercostals.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        These pass from the inferior edge of the costal groove to the
                                                                                        superior margins of the ribs below.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        These muscle fibers extend in a posteroinferior direction and again
                                                                                        pass in an oblique manner.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        These muscles are only present from the parasternal area to the
                                                                                        angle of the ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Their action is to depress the ribs in forced expiration.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Innermost intercostal muscles</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The fibers of these muscles extend in the same direction as the
                                                                                        internal intercostal muscles.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        They extend from the medial edge of the costal groove to the medial
                                                                                        surface of the rib below and are mainly present within the lateral
                                                                                        thoracic wall.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        However, these muscles are notable in that the neurovascular bundles
                                                                                        pass through the opening between the internal and innermost
                                                                                        intercostal muscle layers.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        These muscles assist with the depression of the ribs in forced
                                                                                        expiration.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Subcostales</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The subcostales muscles are located within the same plane as the
                                                                                        innermost intercostals.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        They are unique in that they may span one or multiple ribs and
                                                                                        become more numerous within the inferior regions of the posterior
                                                                                        thoracic wall.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        They extend from the inner surface of one rib to the inner surface
                                                                                        of either the next rib or even the one below that.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This muscle assists the internal intercostal muscles.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Transversus thoracis</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        These muscles are found deep within the anterior thoracic wall.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The transverse thoracic muscles originate from the posterior surface
                                                                                        of the xiphoid process and the lower part of the body of the
                                                                                        sternum.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The fibers pass superolaterally to insert into the internal surface
                                                                                        of costal cartilages of ribs two to six.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This muscle assists in the depression of the ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Serratus posterior</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This muscle is present posteriorly within the thoracic wall.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        It is split into superior and inferior fibers.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The inferior fibers originate from the spinous processes of the T11
                                                                                        to L2vertebrae and attach to the lower borders of ribs eight to
                                                                                        twelve near the angle.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The part of the muscle is thought to depress the ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        The superior fibers originate from the spinous processes of the C7
                                                                                        to T3 vertebrae and attach to the superior borders of ribs two to
                                                                                        four.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        This part of the muscle is thought to elevate the ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                            <p style={{ textAlign: "justify" }}>
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        fontFamily: "Inter",
                                                                                        color: "rgb(33, 37, 41)"
                                                                                    }}
                                                                                >
                                                                                    <strong>Lavatoriescostarum</strong>
                                                                                </span>
                                                                            </p>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Levatoriescostarum originates from the transverse processes of
                                                                                        vertebrae C7 to T11 and attaches to the external surface between the
                                                                                        tubercle and the angle of the rib below.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                            <ul style={{ textAlign: "justify" }}>
                                                                                <li>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        Its action is to assist in the elevation of the ribs.
                                                                                    </span>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex justify-content-center'>
                                                                    <ReactPlayer
                                                                        url='https://s3.ap-south-1.amazonaws.com/katbook1.0/624296a2143cf32e7078542d/178873198014_typical-thoracic-vertebrae.mp4'
                                                                        controls={true}
                                                                    />
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                        <Accordion.Item eventKey="1-1-5">
                                                            <Accordion.Header>Classifications of Ribs</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div
                                                                    className="ViewScroll 2 additionalData col-lg-12 ViewAddtChat "
                                                                >
                                                                    <div id={0} className="book padding-0 col">
                                                                        <div className="" style={{ zoom: "100%" }}>
                                                                            <div
                                                                                className="root_container_display"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    marginTop: 10,
                                                                                    marginBottom: 0,
                                                                                    justifyContent: "space-between"
                                                                                }}
                                                                            />
                                                                            <div className="AddiContent_Text  ">
                                                                                <p style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            textDecoration: "underline",
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(53, 65, 77)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Introduction:</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The ribs are curved, flat bones that form the majority of the
                                                                                            thoracic cage.&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            They are extremely light, but highly resilient; contributing to
                                                                                            their role in protecting the internal thoracic organs.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            There are twelve pairs of ribs, all of which articulate with the
                                                                                            vertebral column.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            However, only seven have a direct articulation with the sternum.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            There are two classifications of ribs
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                                <ul>
                                                                                    <li>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The typical ribs have a generalized structure, while the atypical
                                                                                            ribs have variations on this structure.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            Ribs have a rounded, smooth superior border. The inferior border
                                                                                            is thin and sharp.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id={1} className="book padding-0 col">
                                                                        <div className="" style={{ zoom: "100%" }}>
                                                                            <div
                                                                                className="root_container_display"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    marginTop: 10,
                                                                                    marginBottom: 0,
                                                                                    justifyContent: "space-between"
                                                                                }}
                                                                            />
                                                                            <div className="mob-display">
                                                                                <div
                                                                                    className="bookM_func_OPQ_addit"
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        width: "100%",
                                                                                        padding: 20,
                                                                                        justifyContent: "flex-end"
                                                                                    }}
                                                                                >
                                                                                    <div className="bookM_unchecked">
                                                                                        <img
                                                                                            className="pointer"
                                                                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                            alt=""
                                                                                            style={{ width: 14 }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="AddiContent_Text  ">
                                                                                <p style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            textDecoration: "underline",
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(53, 65, 77)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Typical rib:</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The typical rib consists of
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                                <p>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Head</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The head is wedge-shaped and has two articular facets separated by
                                                                                            a wedge of bone.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            One facet articulates with the numerically corresponding vertebra,
                                                                                            and the other articulates with the vertebra above.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <p>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Neck</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The neck contains no bony prominences, but simply connects the
                                                                                            head with the body.&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            Where the neck meets the body there is a roughed tubercle, with a
                                                                                            facet for articulation with the transverse process of the
                                                                                            corresponding vertebra.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <p style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Tubercle</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The tubercle, immediately after the neck, is a prominence on the
                                                                                            external surface of the rib which contains a medial facet that
                                                                                            articulates with the transverse process of the numerically
                                                                                            corresponding vertebra. This area is called the articular
                                                                                            part.&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The non-articular part provides attachment for the lateral
                                                                                            costotransverse ligament.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <p style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Body</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The body or shaft of the rib is flat and curved. &nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The internal surface of the shaft has a groove for the
                                                                                            neurovascular supply of the thorax, protecting the vessels and
                                                                                            nerves from damage.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <p>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Angle</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The angle marks the lateral limit of the attachment of the deep
                                                                                            back muscles to the ribs.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                                <p>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Attachments</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            There are a number of muscles related to typical ribs.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The intercostal muscles are situated in the intercostal spaces.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontFamily: "Inter",
                                                                                                fontSize: 20,
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            There are three main groups running from superficial to
                                                                                            deep:&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The diaphragm rises from the inner surfaces of the costal
                                                                                            cartilages on the sixth rib.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The serratus anterior muscles originate anterolaterally from ribs
                                                                                            1 to 8.&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The pectoralis major and minor muscles arise from superior
                                                                                            anterior ribs.&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The latissimus dorsi has origins in the 9th to 12th ribs.&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            Scalenus anterior, posterior,aa and medius muscles have
                                                                                            attachments in the first and second ribs.&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The rectus abdominis inserts at the xiphisternum and 5th to 7th
                                                                                            costal cartilages
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id={2} className="book padding-0 col">
                                                                        <div className="" style={{ zoom: "100%" }}>
                                                                            <div
                                                                                className="root_container_display"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    marginTop: 10,
                                                                                    marginBottom: 0,
                                                                                    justifyContent: "space-between"
                                                                                }}
                                                                            />
                                                                            <div className="mob-display">
                                                                                <div
                                                                                    className="bookM_func_OPQ_addit"
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        width: "100%",
                                                                                        padding: 20,
                                                                                        justifyContent: "flex-end"
                                                                                    }}
                                                                                >
                                                                                    <div className="bookM_unchecked">
                                                                                        <img
                                                                                            className="pointer"
                                                                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                            alt=""
                                                                                            style={{ width: 14 }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="AddiContent_Text  ">
                                                                                <p style={{ textAlign: "justify" }}>
                                                                                    <span
                                                                                        style={{
                                                                                            textDecoration: "underline",
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(53, 65, 77)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Atypical Ribs:</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The atypical ribs are the 1st, 2nd, and the 10th – 12th ribs
                                                                                            characterized by the following features:
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            Have one or two facets, and a rough area on the superior surface,
                                                                                            e.g, the tuberosity for serratus anterior of the second rib.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>



                                                                                <p>
                                                                                    <span
                                                                                        style={{
                                                                                            fontFamily: "Inter",
                                                                                            fontSize: 20,
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>True (Vertebrocostal) ribs:</strong>&nbsp;
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The 1st to 7th ribs fall under this group, and they attach
                                                                                            directly to the sternum through their costal cartilages.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                                <p>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>False (vertebrochondral) ribs:&nbsp;</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The 8th, 9th, and 10th ribs are referred to as false ribs because
                                                                                            their cartilages are connected to the costal cartilage of the rib
                                                                                            above them; thus their connection with the sternum is indirect.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                                <p>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Floating (vertebral, free) ribs:&nbsp;</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <ul>
                                                                                    <li>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The 11th and 12th ribs are grouped as floating ribs.&nbsp;&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            They articulate only to the vertebral column, thus hanging
                                                                                            freely.&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            Some authors group the 10th rib with the floating ribs; and the
                                                                                            rudimentary cartilages of these floating ribs do not connect even
                                                                                            indirectly with the sternum, instead, they end in the posterior
                                                                                            abdominal musculature.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id={3} className="book padding-0 col">
                                                                        <div className="" style={{ zoom: "100%" }}>
                                                                            <div
                                                                                className="root_container_display"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    marginTop: 10,
                                                                                    marginBottom: 0,
                                                                                    justifyContent: "space-between"
                                                                                }}
                                                                            />
                                                                            <div className="mob-display">
                                                                                <div
                                                                                    className="bookM_func_OPQ_addit"
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        width: "100%",
                                                                                        padding: 20,
                                                                                        justifyContent: "flex-end"
                                                                                    }}
                                                                                >
                                                                                    <div className="bookM_unchecked">
                                                                                        <img
                                                                                            className="pointer"
                                                                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgB7VMxDgFREJ2Z/79EotgjcAGiQIkTuMIqVkuxSvyNSiKoKSh14gRUopG4gbiBGv+PFY0oLK14yTQz77284g26De1IaVwCdCAC5yuNSAmrCakYRQbEjFJWSwRw2NjlZNCdveN7zZZLlooEX+Iv+CXBvZDhJD8SeM2grgQfYoJXNb/deb3LJ9ekkjwFtiAMZu87K2Hh+Z3S5YpVAPMQMMAJBVUU8TBsbjDuB6Mnw2zN1zqmeBWG2Vvio0gXyltBnGKg3qQfzF8j7DbrdS5fXhqCeCJ8oBvK1USo4XDiVgAAAABJRU5ErkJggg=="
                                                                                            alt=""
                                                                                            style={{ width: 14 }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="AddiContent_Text  ">
                                                                                <p>
                                                                                    <span
                                                                                        style={{
                                                                                            textDecoration: "underline",
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(53, 65, 77)"
                                                                                        }}
                                                                                    >
                                                                                        <strong>Function:</strong>
                                                                                    </span>
                                                                                </p>
                                                                                <p>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontFamily: "Inter",
                                                                                            color: "rgb(33, 37, 41)"
                                                                                        }}
                                                                                    >
                                                                                        There are several functions of ribs. These functions include:
                                                                                    </span>
                                                                                </p>

                                                                                <ul>
                                                                                    <li>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            Protection of the thoracic contents is most important. Organs in
                                                                                            the thorax include the lungs, heart, trachea, esophagus, and
                                                                                            diaphragm as well as many muscles, nerves, and vascular
                                                                                            structures.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The ribs provide a bony cavity that wraps around the body, keeping
                                                                                            organs safe and sound within the body.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                                <ul>
                                                                                    <li>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            When breathing, the diaphragm muscle in the lower thorax moves
                                                                                            downward.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            While this is happening, small intercostal muscles near the ribs
                                                                                            contract, moving ribs up and expanding the thorax.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            This expansion creates a pressure differential between the air in
                                                                                            the body and the ambient air outside the body.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            Ambient air rushes into the lungs where they do their job of gas
                                                                                            exchange.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            The diaphragm then relaxes, the ribs move downward, and the
                                                                                            pressure of the thorax increases, pushing air out
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>

                                                                                <ul>
                                                                                    <li>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            Ribs are essential movers in the thorax to allow breathing to
                                                                                            occur.&nbsp;
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <ul>
                                                                                    <li style={{ textAlign: "justify" }}>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                                fontFamily: "Inter",
                                                                                                color: "rgb(33, 37, 41)"
                                                                                            }}
                                                                                        >
                                                                                            They move and act as 12 pairs of bucket handles, moving up and
                                                                                            down while breathing.
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1-2">
                                                <Accordion.Header>Thoracic Inlet and Thoracic Wall</Accordion.Header>
                                                <Accordion.Body>
                                                    Sub-Accordion content #2.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1-3">
                                                <Accordion.Header>Mediastinum and Respiratory Organs</Accordion.Header>
                                                <Accordion.Body>
                                                    Sub-Accordion content #3.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        {/* End of Sub-Accordion */}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <BiHighlight size={20} /> &nbsp; &nbsp;<span className='accordion_font'>Highyield</span>
                                    </Accordion.Header>
                                    <Accordion.Body>

                                        <div className='title_font pb-3'>
                                            Anatomic Position and Planes
                                        </div>

                                        <Accordion defaultActiveKey="2">
                                            <Accordion.Item eventKey="2-1">
                                                <Accordion.Header>Quick Review</Accordion.Header>
                                                <Accordion.Body>
                                                    <div style={{ fontSize: "20px", paddingBottom: "15px" }}>
                                                        The thoracic cage is a skeletal structure that supports the thorax, providing attachment
                                                        points for muscles of the thorax, upper limbs, back, and diaphragm. Made of bones and cartilage,
                                                        it plays a crucial role in respiration by raising or lowering intrathoracic pressure. The main
                                                        components of the thoracic cage are the sternum, ribs, thoracic vertebrae, and costal cartilages.
                                                    </div>

                                                    <div>
                                                        <h5>Main Parts of the Thoracic Cage:</h5>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: "20px", fontWeight: "500" }}>1. Sternum:</span>
                                                        <span style={{ fontSize: "18px" }}> The elongated bone anchoring the anterior thoracic cage, consisting of:</span>
                                                    </div>
                                                    <div>
                                                        <ul>
                                                            <li>
                                                                <span style={{ fontSize: "20px", fontWeight: "500" }}>Manubrium:</span>
                                                                <span style={{ fontSize: "18px" }}> The superior part, with a jugular notch and clavicular notches for
                                                                    articulation with the clavicle and the first ribs.
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style={{ fontSize: "20px", fontWeight: "500" }}>Body:</span>
                                                                <span style={{ fontSize: "18px" }}> The central part, where ribs 2–7 attach. The sternal angle is an
                                                                    important landmark for rib identification.
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span style={{ fontSize: "20px", fontWeight: "500" }}>Xiphoid Process:</span>
                                                                <span style={{ fontSize: "18px" }}> The small, inferior part, which ossifies with age and serves as a
                                                                    landmark for the diaphragm, liver, and heart.
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: "20px", fontWeight: "500" }}>2. Ribs:</span>
                                                        <span style={{ fontSize: "18px" }}> There are 12 pairs of ribs that articulate with the thoracic vertebrae.
                                                            The upper 7 ribs attach directly to the sternum, while ribs 8–12 either
                                                            indirectly connect or are free-floating.
                                                        </span>
                                                    </div>
                                                    <div className='py-3'>
                                                        <span style={{ fontSize: "20px", fontWeight: "500" }}>3. Thoracic Vertebrae:</span>
                                                        <span style={{ fontSize: "18px" }}> The 12 thoracic vertebrae form the spine of the thorax. They are characterized
                                                            by costal facets that allow articulation with the ribs.
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: "20px", fontWeight: "500" }}>4. Intercostal Spaces:</span>
                                                        <span style={{ fontSize: "18px" }}> The spaces between ribs, containing muscles, nerves, and blood vessels. They
                                                            allow the thoracic cage to expand during respiration.
                                                        </span>
                                                    </div>

                                                    <div className='pt-3'>
                                                        <h5>Formation and Structure:</h5>
                                                    </div>

                                                    <div>
                                                        <ul>
                                                            <li><span style={{ fontSize: "20px", fontWeight: "500" }}>Anteriorly:</span>
                                                                <span style={{ fontSize: "18px" }}> The sternum.</span>
                                                            </li>
                                                            <li><span style={{ fontSize: "20px", fontWeight: "500" }}>Posteriorly:</span>
                                                                <span style={{ fontSize: "18px" }}> The thoracic vertebrae and intervertebral discs.</span>
                                                            </li>
                                                            <li><span style={{ fontSize: "20px", fontWeight: "500" }}>Laterally:</span>
                                                                <span style={{ fontSize: "18px" }}> The ribs and their associated costal cartilages.</span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div style={{ fontSize: "20px" }}>
                                                        The thoracic cage is conical in shape, providing space for respiration and protection
                                                        for the heart, lungs, and other vital organs. It is reinforced by several joints,
                                                        including costochondral, interchondral, sternocostal, and costovertebral joints,
                                                        which stabilize the structure and allow movement during breathing.
                                                    </div>

                                                    <div className='pt-3'>
                                                        <h5>Function:</h5>
                                                    </div>
                                                    <div style={{ fontSize: "20px" }}>
                                                        The thoracic cage protects internal organs, supports the upper body, and assists in
                                                        respiration by enabling the expansion and contraction of the thoracic cavity.
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>


                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        <BsChatSquareText size={20} /> &nbsp; &nbsp;<span className='accordion_font'>MCQs</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className='w-100'>
                                            <Col md={8}>
                                                <div>
                                                    <div className='d-flex flex-column gap-3'>
                                                        <div>
                                                            <span style={{ fontSize: "25px" }}>1</span>/5.
                                                            <span style={{ fontSize: "20px" }}> Which of the following structures forms the anterior part of the thoracic cage?</span>
                                                        </div>
                                                        <div style={{ width: "max-content" }} onClick={() => setShowAnswer(true)}>
                                                            {showAnswer ? (
                                                                <>
                                                                    <span className='correct_circle'><span className='select_center_correct'></span></span> &nbsp;
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span className='select_center cursor'></span> &nbsp;
                                                                </>
                                                            )}

                                                            <span style={{ fontSize: "18px", cursor: "pointer" }}>&nbsp; a) Ribs</span>
                                                        </div>
                                                        <div>
                                                            <span className='select_center cursor'></span> &nbsp;
                                                            <span style={{ fontSize: "18px" }}>&nbsp; b) Thoracic vertebrae</span>
                                                        </div>
                                                        <div>
                                                            <span className='select_center'></span> &nbsp;
                                                            <span style={{ fontSize: "18px" }}>&nbsp; c) Sternum</span>
                                                        </div>
                                                        <div>
                                                            <span className='select_center'></span> &nbsp;
                                                            <span style={{ fontSize: "18px" }}>&nbsp; d) Costal cartilages</span>
                                                        </div>
                                                    </div>

                                                    {showAnswer &&
                                                        <div>
                                                            <div className='py-3'>
                                                                <span style={{ fontSize: "18px", color: "#008C50", fontWeight: "500" }}>Correct Answer:</span> <span style={{ fontSize: "19px", fontWeight: "500" }}>C) Sternum </span>
                                                            </div>

                                                            <div>
                                                                <span style={{ fontSize: "19px", fontWeight: "500" }}>Explanation: </span>
                                                                The sternum is the elongated bone at the front (anterior) of the
                                                                thoracic cage. It anchors the ribs via costal cartilages and
                                                                consists of three parts: manubrium, body, and xiphoid process.
                                                                The ribs and thoracic vertebrae form the lateral and posterior
                                                                parts of the thoracic cage.
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className='pt-5'>
                                                        <div className='d-flex align-items-center justify-content-center gap-3'>
                                                            <div className='button1'>
                                                                <div style={{ color: "#fff" }}>
                                                                    <span className='arrow_button'>
                                                                        <FaArrowLeftLong size={20} />
                                                                    </span>&nbsp; &nbsp; Previous
                                                                </div>
                                                            </div>

                                                            <div className="button2">
                                                                <div style={{ color: "#fff" }}>
                                                                    &nbsp; &nbsp; &nbsp;Next&nbsp; &nbsp; &nbsp;&nbsp;
                                                                    <span className='arrow_button'>
                                                                        <FaArrowRightLong size={20} />
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div onClick={() => setShowAnswer(false)}
                                                                className='button3'>
                                                                Cancel
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div>
                                                    <h5>Questions</h5>
                                                </div>
                                                <div className='d-flex flex-column gap-2'>
                                                    <div className='que_font1'>
                                                        1. Which of the following structures forms the
                                                        anterior part of the thoracic cage?
                                                    </div>
                                                    <div className='que_font2'>
                                                        2. Which rib attaches to the sternum at the level
                                                        of the sternal angle?
                                                    </div>
                                                    <div className='que_font2'>
                                                        3. Which part of the sternum serves as a landmark for the superior
                                                        limit of the liver and the inferior border of the heart?
                                                    </div>
                                                    <div className='que_font2'>
                                                        4. Which ribs are classified as "floating ribs"?
                                                    </div>
                                                    <div className='que_font2'>
                                                        5. Which of the following is the function of the intercostal
                                                        spaces in the thoracic cage?
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        <RiFilePaperLine size={20} /> &nbsp; &nbsp;<span className='accordion_font'>Cases</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className='w-100'>
                                            <Col md={8}>
                                                <div>
                                                    <div className='d-flex flex-column gap-3'>
                                                        <div>
                                                            <span style={{ fontSize: "25px" }}>1</span>/5.
                                                            <span style={{ fontSize: "20px" }}> Which structure is most likely involved in this patient's pain?</span>
                                                        </div>

                                                        <div style={{ width: "max-content" }} onClick={() => setShowAnswerCases(true)}>
                                                            {showAnswerCases ? (
                                                                <>
                                                                    <span className='correct_circle'><span className='select_center_correct'></span></span> &nbsp;
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span className='select_center cursor'></span> &nbsp;
                                                                </>
                                                            )}
                                                            <span style={{ fontSize: "18px", cursor: "pointer" }}>&nbsp; a) Costovertebral joint </span>
                                                        </div>
                                                        <div>
                                                            <span className='select_center'></span> &nbsp;
                                                            <span style={{ fontSize: "18px" }}>&nbsp; b) Intercostal muscles </span>
                                                        </div>
                                                        <div>
                                                            <span className='select_center'></span> &nbsp;
                                                            <span style={{ fontSize: "18px" }}>&nbsp; c) Sternocostal joint </span>
                                                        </div>
                                                        <div>
                                                            <span className='select_center'></span> &nbsp;
                                                            <span style={{ fontSize: "18px" }}>&nbsp; d) Thoracic vertebrae </span>
                                                        </div>
                                                    </div>

                                                    {showAnswerCases &&
                                                        <div>
                                                            <div className='py-3'>
                                                                <span style={{ fontSize: "18px", color: "#008C50", fontWeight: "500" }}>Correct Answer:</span> <span style={{ fontSize: "19px", fontWeight: "500" }}>C) Sternocostal joint </span>
                                                            </div>

                                                            <div>
                                                                <span style={{ fontSize: "19px", fontWeight: "500" }}>Explanation: </span>
                                                                The patient's pain is localized near the sternum and worsens
                                                                with inspiration, suggesting involvement of the sternocostal
                                                                joint, where the ribs meet the sternum. This type of pain is
                                                                commonly associated with costochondritis or inflammation of
                                                                the costal cartilage at the sternocostal junction.
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className='pt-5'>
                                                        <div className='d-flex align-items-center justify-content-center gap-3'>
                                                            <div className='button1'>
                                                                <div style={{ color: "#fff" }}>
                                                                    <span className='arrow_button'>
                                                                        <FaArrowLeftLong size={20} />
                                                                    </span>&nbsp; &nbsp; Previous
                                                                </div>
                                                            </div>

                                                            <div className="button2">
                                                                <div style={{ color: "#fff" }}>
                                                                    &nbsp; &nbsp; &nbsp;Next&nbsp; &nbsp; &nbsp;&nbsp;
                                                                    <span className='arrow_button'>
                                                                        <FaArrowRightLong size={20} />
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div onClick={() => setShowAnswerCases(false)}
                                                                className='button3'>
                                                                Cancel
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div>
                                                    <h5>Questions</h5>
                                                </div>
                                                <div className='d-flex flex-column gap-2'>
                                                    <div className='que_font1'>
                                                        1. Which structure is most likely involved in this patient's pain?
                                                    </div>
                                                    <div className='que_font2'>
                                                        2. What is the most likely diagnosis for this patient given the history
                                                        of chest wall tenderness and pain with inspiration?
                                                    </div>
                                                    <div className='que_font2'>
                                                        3. Which rib attaches to the sternum at the level of the sternal angle,
                                                        an important landmark for rib identification?
                                                    </div>
                                                    <div className='que_font2'>
                                                        4. Which of the following muscles is involved in expanding the thoracic
                                                        cage during inspiration?
                                                    </div>
                                                    <div className='que_font2'>
                                                        5. If the patient’s pain were due to a rib fracture, which part of the
                                                        rib would be most vulnerable to injury?
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                        <MdAddLink size={20} /> &nbsp; &nbsp;<span className='accordion_font'>External references</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='d-flex flex-column gap-4 pb-4'>
                                            <div className='d-flex flex-column gap-1'>
                                                <div>
                                                    Anatomy, Thorax
                                                </div>
                                                <div>
                                                    <a href='https://www.ncbi.nlm.nih.gov/books/NBK557710' target='_blank' >
                                                        https://www.ncbi.nlm.nih.gov/books/NBK557710
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column gap-1'>
                                                <div>
                                                    Thorax: Anatomy, wall, cavity, organs & neurovasculature
                                                </div>
                                                <div>
                                                    <a href='https://www.kenhub.com/en/library/anatomy/thorax' target='_blank' >
                                                        https://www.kenhub.com/en/library/anatomy/thorax
                                                    </a>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column gap-1'>
                                                <div>
                                                    Anatomy, Thorax, Sternum
                                                </div>
                                                <div>
                                                    <a href='https://www.ncbi.nlm.nih.gov/books/NBK541141' target='_blank' >
                                                        https://www.ncbi.nlm.nih.gov/books/NBK541141
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </Col>
            </Row>

            <div>
                <Modal centered size="lg" show={showIndexModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Index</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Accordion>
                                <Accordion.Item eventKey="0"  >
                                    <Accordion.Header>
                                        1. Introduction
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        2. Upper Extremity
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        3. Thorax
                                    </Accordion.Header>
                                    <Accordion.Body style={{ backgroundColor: "#18498b" }}>
                                        {/* End of Sub-Accordion */}
                                        <Accordion defaultActiveKey="2">
                                            <Accordion.Item eventKey="2-1">
                                                <Accordion.Header>Bones of Thoracic Cage</Accordion.Header>
                                                <Accordion.Body>
                                                    Sub-Accordion content #1.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2-2">
                                                <Accordion.Header>Thoracic Inlet and Thoracic Wall</Accordion.Header>
                                                <Accordion.Body>
                                                    Sub-Accordion content #2.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2-3">
                                                <Accordion.Header>Mediastinum and Respiratory Organs</Accordion.Header>
                                                <Accordion.Body>
                                                    Sub-Accordion content #3.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        {/* End of Sub-Accordion */}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        4. Head and Neck
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        5. Vertebral Column and Spinal Cord, Cranial Cavity and Brain
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                        6. Cranial Nerves
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>
                                        7. Abdomen, Pelvis and Perineum
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>
                                        8. Lower Limb
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt mollit anim id est laborum.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Modal.Body>

                </Modal>
            </div>

            <div className='fixed '>
                <div className='fix d-flex justify-content-between align-items-center px-2'>
                    <div className='d-flex align-items-center justify-content-center gap-3'>
                        <div>
                            <img src={smacc} alt='' />
                        </div>
                        <div>
                            K - Smacc
                        </div>
                    </div>
                    <div>
                        <IoChevronUp size={18} style={{ color: "white" }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accordionn