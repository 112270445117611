import React from 'react'

function NotesIcon({width,height,color,title}) {
  return (
<svg width={width} height={height} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.66406 4.58366C3.66406 4.34054 3.76064 4.10739 3.93255 3.93548C4.10446 3.76357 4.33761 3.66699 4.58073 3.66699H13.7474C13.9905 3.66699 14.2237 3.76357 14.3956 3.93548C14.5675 4.10739 14.6641 4.34054 14.6641 4.58366C14.6641 4.82677 14.5675 5.05993 14.3956 5.23184C14.2237 5.40375 13.9905 5.50033 13.7474 5.50033H4.58073C4.33761 5.50033 4.10446 5.40375 3.93255 5.23184C3.76064 5.05993 3.66406 4.82677 3.66406 4.58366ZM3.66406 8.25033C3.66406 8.00721 3.76064 7.77405 3.93255 7.60214C4.10446 7.43024 4.33761 7.33366 4.58073 7.33366H13.7474C13.9905 7.33366 14.2237 7.43024 14.3956 7.60214C14.5675 7.77405 14.6641 8.00721 14.6641 8.25033C14.6641 8.49344 14.5675 8.7266 14.3956 8.89851C14.2237 9.07041 13.9905 9.16699 13.7474 9.16699H4.58073C4.33761 9.16699 4.10446 9.07041 3.93255 8.89851C3.76064 8.7266 3.66406 8.49344 3.66406 8.25033ZM4.58073 11.0003C4.33761 11.0003 4.10446 11.0969 3.93255 11.2688C3.76064 11.4407 3.66406 11.6739 3.66406 11.917C3.66406 12.1601 3.76064 12.3933 3.93255 12.5652C4.10446 12.7371 4.33761 12.8337 4.58073 12.8337H13.7474C13.9905 12.8337 14.2237 12.7371 14.3956 12.5652C14.5675 12.3933 14.6641 12.1601 14.6641 11.917C14.6641 11.6739 14.5675 11.4407 14.3956 11.2688C14.2237 11.0969 13.9905 11.0003 13.7474 11.0003H4.58073ZM3.66406 15.5837C3.66406 15.3405 3.76064 15.1074 3.93255 14.9355C4.10446 14.7636 4.33761 14.667 4.58073 14.667H8.2474C8.49051 14.667 8.72367 14.7636 8.89558 14.9355C9.06749 15.1074 9.16406 15.3405 9.16406 15.5837C9.16406 15.8268 9.06749 16.0599 8.89558 16.2318C8.72367 16.4037 8.49051 16.5003 8.2474 16.5003H4.58073C4.33761 16.5003 4.10446 16.4037 3.93255 16.2318C3.76064 16.0599 3.66406 15.8268 3.66406 15.5837Z" 
fill={color} />
<path fillRule="evenodd" clipRule="evenodd" d="M0 2.75C0 2.02065 0.289731 1.32118 0.805456 0.805456C1.32118 0.289731 2.02065 0 2.75 0H15.5833C16.3127 0 17.0122 0.289731 17.5279 0.805456C18.0436 1.32118 18.3333 2.02065 18.3333 2.75V17.4167C18.3333 18.146 18.0436 18.8455 17.5279 19.3612C17.0122 19.8769 16.3127 20.1667 15.5833 20.1667H2.75C2.02065 20.1667 1.32118 19.8769 0.805456 19.3612C0.289731 18.8455 0 18.146 0 17.4167V2.75ZM2.75 1.83333H15.5833C15.8264 1.83333 16.0596 1.92991 16.2315 2.10182C16.4034 2.27373 16.5 2.50688 16.5 2.75V17.4167C16.5 17.6598 16.4034 17.8929 16.2315 18.0648C16.0596 18.2368 15.8264 18.3333 15.5833 18.3333H2.75C2.50688 18.3333 2.27373 18.2368 2.10182 18.0648C1.92991 17.8929 1.83333 17.6598 1.83333 17.4167V2.75C1.83333 2.50688 1.92991 2.27373 2.10182 2.10182C2.27373 1.92991 2.50688 1.83333 2.75 1.83333Z" 
fill={color} />
<title>{title}</title>
</svg>

  )
}
NotesIcon.defaultProps = {
    width: '19',
    height: '21',
    color: "black",
  }
export default NotesIcon