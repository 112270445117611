import './App.css';
import Accordion from './Accordion/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-html5video/dist/styles.css';

function App() {
  return (
    <div>
     <Accordion />
    </div>
  );
}

export default App;
