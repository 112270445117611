import React from 'react'

function BookmarkIcon({width,height,color,title}) {
  return (
    <svg width={width} height={height} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5714 0H2.42857C1.09286 0 0 1 0 2.22222V19.4823C0 19.7128 0.249182 19.8572 0.44918 19.7425L8.5 15.1282L16.5508 19.7425C16.7508 19.8572 17 19.7128 17 19.4823V2.22222C17 1 15.9071 0 14.5714 0ZM14.5714 15.7692L8.64005 12.638C8.55242 12.5918 8.44758 12.5918 8.35995 12.638L2.42857 15.7692V3.33333C2.42857 2.72222 2.975 2.22222 3.64286 2.22222H13.3571C14.025 2.22222 14.5714 2.72222 14.5714 3.33333V15.7692Z" 
fill={color} />
<title>{title}</title>
</svg>
  )
}
BookmarkIcon.defaultProps = {
    width: '17',
    height: '20',
    color:"#252525",
  }

export default BookmarkIcon