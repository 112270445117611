import React from 'react'

function SearchIcon({width, height,color,title}) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M16.3257 14.8987L21.7057 20.2787C21.8948 20.468 22.001 20.7246 22.0009 20.9921C22.0008 21.2596 21.8945 21.5161 21.7052 21.7052C21.516 21.8943 21.2594 22.0005 20.9919 22.0004C20.7244 22.0003 20.4678 21.894 20.2787 21.7047L14.8987 16.3247C13.2905 17.5704 11.268 18.1566 9.24287 17.9641C7.21772 17.7716 5.34198 16.8148 3.99723 15.2884C2.65248 13.7619 1.93973 11.7806 2.004 9.74729C2.06826 7.71402 2.9047 5.7816 4.34315 4.34315C5.7816 2.9047 7.71402 2.06826 9.74729 2.004C11.7806 1.93973 13.7619 2.65248 15.2884 3.99723C16.8148 5.34198 17.7716 7.21772 17.9641 9.24287C18.1566 11.268 17.5704 13.2905 16.3247 14.8987H16.3257ZM10.0007 15.9997C11.592 15.9997 13.1182 15.3676 14.2434 14.2424C15.3686 13.1172 16.0007 11.591 16.0007 9.99974C16.0007 8.40845 15.3686 6.88232 14.2434 5.7571C13.1182 4.63189 11.592 3.99974 10.0007 3.99974C8.40944 3.99974 6.88332 4.63189 5.7581 5.7571C4.63289 6.88232 4.00074 8.40845 4.00074 9.99974C4.00074 11.591 4.63289 13.1172 5.7581 14.2424C6.88332 15.3676 8.40944 15.9997 10.0007 15.9997Z"
fill={color} />
<title>{title}</title>
</svg>
  )
}
SearchIcon.defaultProps = {
  width: '24',
  height: '24',
    color:"#252525",
  }
export default SearchIcon