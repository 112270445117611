import React from 'react'

function HighlightIcon({width, height,color,title}) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M3.25 22C3.25 21.8011 3.32902 21.6103 3.46967 21.4697C3.61032 21.329 3.80109 21.25 4 21.25H20C20.1989 21.25 20.3897 21.329 20.5303 21.4697C20.671 21.6103 20.75 21.8011 20.75 22C20.75 22.1989 20.671 22.3897 20.5303 22.5303C20.3897 22.671 20.1989 22.75 20 22.75H4C3.80109 22.75 3.61032 22.671 3.46967 22.5303C3.32902 22.3897 3.25 22.1989 3.25 22Z" 
fill={color} />
<path d="M11.5207 14.9288L17.4377 9.0118C16.4422 8.59624 15.538 7.98906 14.7767 7.22479C14.012 6.46325 13.4044 5.55876 12.9887 4.5628L7.07069 10.4798C6.60869 10.9418 6.37769 11.1718 6.17969 11.4268C5.94541 11.7271 5.74455 12.052 5.58069 12.3958C5.44169 12.6868 5.33869 12.9968 5.13169 13.6158L4.04369 16.8828C3.99376 17.0323 3.98647 17.1927 4.02262 17.346C4.05877 17.4994 4.13695 17.6397 4.24837 17.7511C4.3598 17.8625 4.50007 17.9407 4.65344 17.9769C4.80682 18.013 4.96723 18.0057 5.11669 17.9558L8.38269 16.8678C9.00269 16.6608 9.31269 16.5578 9.60369 16.4178C9.94765 16.2546 10.2726 16.0541 10.5727 15.8198C10.8277 15.6208 11.0577 15.3898 11.5197 14.9288H11.5207ZM19.0797 7.36979C19.6697 6.77969 20.0011 5.97938 20.001 5.14494C20.0009 4.3105 19.6693 3.51027 19.0792 2.9203C18.4891 2.33032 17.6888 1.99893 16.8543 1.99902C16.0199 1.99912 15.2197 2.33069 14.6297 2.9208L13.9197 3.6308L13.9507 3.7208C14.2107 4.46979 14.7017 5.4528 15.6247 6.37579C16.3947 7.15012 17.335 7.73366 18.3707 8.07979L19.0797 7.36979Z" 
fill={color} />
<title>{title}</title>
</svg>
  )
}
HighlightIcon.defaultProps = {
  width: '24',
  height: '24',
    color:"#252525",
  }
export default HighlightIcon
