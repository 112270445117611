import React from 'react'

function KlarifyIcon({ width, height, title, color }) {
  return (
    <svg width={width} height={height} viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 21.958L25 21.958M15 21.958L15 20.8023M15 20.8023L5 9.04134M15 20.8023L25 9.04134"
        stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.6737 3.28071L12.6738 3.28055C13.2962 2.64779 14.124 2.29727 15.0016 2.29727C15.8793 2.29727 16.7071 2.64781 17.3296 3.28193M12.6737 3.28071L17.2225 3.387M12.6737 3.28071C12.3675 3.59292 12.1275 3.95559 11.9588 4.36149L12.6737 3.28071ZM17.3296 3.28193L17.2225 3.387M17.3296 3.28193C17.3295 3.28187 17.3295 3.28181 17.3294 3.28175L17.2225 3.387M17.3296 3.28193C17.636 3.59308 17.8759 3.95706 18.0444 4.36135M17.2225 3.387C17.5153 3.68423 17.7446 4.03205 17.906 4.41907C18.0743 4.82254 18.1595 5.25257 18.1595 5.69525C18.1595 6.07215 18.0973 6.44274 17.974 6.79561M18.0444 4.36135C18.0444 4.36135 18.0444 4.36134 18.0444 4.36134L17.9064 4.41891L18.0444 4.36135ZM18.0444 4.36135C18.2204 4.78339 18.3095 5.23307 18.3095 5.69525C18.3095 6.08873 18.2446 6.47603 18.1156 6.84503M18.1156 6.84503L17.974 6.79561M18.1156 6.84503C18.1156 6.84505 18.1156 6.84508 18.1156 6.8451L17.974 6.79561M18.1156 6.84503C17.9915 7.20084 17.8118 7.52897 17.5817 7.82385L17.5814 7.82417C17.1244 8.40637 16.4851 8.82384 15.7788 8.99901L15.7787 8.99905C15.6813 9.02311 15.5931 9.12947 15.5931 9.26954L15.5931 9.66669C15.5931 9.81452 15.4811 9.95834 15.314 9.95834L14.69	16 9.95834C14.5245 9.95834 14.4125 9.81452 14.4125 9.66669L14.4125 9.26954L14.4125 9.26935C14.413 8.92413 14.5192 8.58766 14.7163 8.31421L14.7163 8.3142C14.9146 8.03898 15.1967 7.84414 15.5129 7.76558L15.513 7.76556C15.9701 7.65226 16.3838 7.38398 16.6768 7.0093L16.6769 7.00921C16.973 6.63099 17.129 6.17791 17.129 5.69525C17.129 4.51935 16.1748 3.56357 15.0016 3.56357C13.8285 3.56357 12.8743 4.51935 12.8743 5.69525L12.8743 6.03674C12.8743 6.18457 12.7623 6.3284 12.5952 6.3284L11.9728 6.3284C11.8057 6.3284 11.6937 6.18457 11.6937 6.03674L11.6937 5.69525C11.6937 5.23298 11.7829 4.78464 11.9588 4.36151L17.974 6.79561M15.0016 11.486C14.5499 11.486 14.2062 11.884 14.2062 12.3442C14.2062 12.8045 14.5499 13.2025 15.0016 13.2025C15.4534 13.2025 15.7971 12.8045 15.7971 12.3442C15.7971 11.884 15.4534 11.486 15.0016 11.486Z"
        fill={color} stroke={color} strokeWidth="2" />
      <title>{title}</title>
    </svg>
  )
}

export default KlarifyIcon